import { takeLatest, call, all, put } from "redux-saga/effects";
import {
  auth,
  handleUserProfile,
  getCurrentUser,
  GoogleProvider,
} from "./../../firebase/utils";
import userTypes from "./user.types";
import {
  signInSuccess,
  signOutUserSuccess,
  resetPasswordSuccess,
  userError,
} from "./user.actions";
import { handleResetPasswordAPI, handleUpdateDataUser, handleUpdateEmailUser } from "./user.helpers";

export function* getSnapshotFromUserAuth(user, additionalData = {}) {
  try {
    const userRef = yield call(handleUserProfile, {
      userAuth: user,
      additionalData,
    });
    const snapshot = yield userRef.get();
    yield put(
      signInSuccess({
        id: snapshot.id,
        ...snapshot.data(),
      })
    );
  } catch (err) {
    // console.log(err);
  }
}

export function* emailSignIn({ payload: { email, password } }) {
  try {
    const { user } = yield auth.signInWithEmailAndPassword(email, password);
    yield getSnapshotFromUserAuth(user);
  } catch (err) {
    //alert(err)
    //console.log(err);
    const error = ["Le mot de passe ou le mail est incorrect."];
    yield put(userError(error));
  }
}

export function* onEmailSignInStart() {
  yield takeLatest(userTypes.EMAIL_SIGN_IN_START, emailSignIn);
}

export function* updateDataUser({
  payload: {
    userID,
    displayName,
    address,
    city,
    country,
    phone,
    company,
    apeCode,
    addressComplement,
  },
}) {
  try {
    yield handleUpdateDataUser(
      userID,
      displayName,
      address,
      city,
      country,
      phone,
      company,
      apeCode,
      addressComplement
    );
    // const user = yield call(handleUpdateDataUser,
    //   userID,
    //   displayName,
    //   address,
    //   city,
    //   country,
    //   phone,
    //   company,
    //   apeCode,
    //   addressComplement,
    // );

    // const userAuth = yield getCurrentUser();
    // if (!userAuth) return;
    // yield getSnapshotFromUserAuth(userAuth);
    //yield call(handleUpdateDataUser, displayName,address,city,country,phone,company,apeCode,addressComplement);
  } catch (err) {
    //alert(err)
    console.log(err);
    const error = ["Les données rentraient sont erronées"];
    yield put(userError(error));
  }
}

export function* onUpdateDataUserStart() {
  yield takeLatest(userTypes.UPDATE_USER_DATA, updateDataUser);
}

export function* updateEmailUser({ payload: { userID, email } }) {
  try {
    yield handleUpdateEmailUser(userID, email);
  } catch (err) {
    console.log(err);
    const error = ["Veuillez-vous déconnecter et reconnecter pour changer votre email"];
    yield put(userError(error));
  }
}

export function* onUpdateEmailUserStart() {
  yield takeLatest(userTypes.UPDATE_USER_EMAIL, updateEmailUser);
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser();
    if (!userAuth) return;
    yield getSnapshotFromUserAuth(userAuth);
  } catch (err) {
    // console.log(err);
  }
}

export function* onCheckUserSession() {
  yield takeLatest(userTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* signOutUser() {
  try {
    yield auth.signOut();
    yield put(signOutUserSuccess());
  } catch (err) {
    // console.log(err);
  }
}

export function* onSignOutUserStart() {
  yield takeLatest(userTypes.SIGN_OUT_USER_START, signOutUser);
}

export function* signUpUser({
  payload: {
    displayName,
    email,
    password,
    confirmPassword,
    address,
    city,
    country,
    phone,
    company,
    apeCode,
    isPro,
    addressComplement,
  },
}) {
  if (password !== confirmPassword) {
    const err = ["Le mot de passe ne correspond pas"];

    // yield put(
    //   userError(err)
    // );

    alert(err);
    return;
  }

  try {
    const { user } = yield auth.createUserWithEmailAndPassword(email, password);
    const additionalData = {
      displayName,
      address,
      city,
      country,
      phone,
      company,
      apeCode,
      isPro,
      addressComplement,
    };
    yield getSnapshotFromUserAuth(user, additionalData);
  } catch (err) {
    console.log(err);
  }
}

export function* onSignUpUserStart() {
  yield takeLatest(userTypes.SIGN_UP_USER_START, signUpUser);
}

export function* resetPassword({ payload: { email } }) {
  try {
    yield call(handleResetPasswordAPI, email);
    yield put(resetPasswordSuccess());
  } catch (err) {
    yield put(userError(err));
  }
}

export function* onResetPasswordStart() {
  yield takeLatest(userTypes.RESET_PASSWORD_START, resetPassword);
}

export function* googleSignIn() {
  try {
    const { user } = yield auth.signInWithPopup(GoogleProvider);
    yield getSnapshotFromUserAuth(user);
  } catch (err) {
    // console.log(err);
  }
}

export function* onGoogleSignInStart() {
  yield takeLatest(userTypes.GOOGLE_SIGN_IN_START, googleSignIn);
}

export default function* userSagas() {
  yield all([
    call(onEmailSignInStart),
    call(onUpdateDataUserStart),
    call(onUpdateEmailUserStart),
    call(onCheckUserSession),
    call(onSignOutUserStart),
    call(onSignUpUserStart),
    call(onResetPasswordStart),
    call(onGoogleSignInStart),
  ]);
}
