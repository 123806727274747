import React from "react";
import { BiMinus, BiPlus } from "react-icons/bi";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import {
  removeCartItem,
  addProduct,
  reduceCartItem,
} from "./../../redux/Cart/cart.actions";
import { FaTrashAlt } from "react-icons/fa";

const ProductCart = (product) => {
  const dispatch = useDispatch();
  const { documentID, imageURL, title, price, quantity, reference } = product;

  const handleRemoveCartItem = (documentID) => {
    dispatch(
      removeCartItem({
        documentID,
      })
    );
  };

  const handleAddProduct = (product) => {
    dispatch(addProduct(product));
  };

  const handleReduceItem = (product) => {
    dispatch(reduceCartItem(product));
  };

  return (
    <div className="w-full p-1 px-2 rounded-lg bg-cartItem flex items-center gap-2">
      <img
        src={imageURL}
        className="w-20 h-20 max-w-[60px] rounded-full object-contain"
        alt={title}
      />

      {/* name section */}
      <div className="flex flex-col gap-2">
        <p className="text-base text-gray-50">{title}</p>
        <p className="text-xs text-gray-50">Ref:{reference}</p>
        <p className="text-sm block text-gray-300 font-semibold">
          {(parseFloat(price.replace(",", ".")) * quantity)
            .toFixed(2)
            .toString()
            .replace(".", ",")}{" "}
          € <span className="text-xs font-normal ">TTC</span>
        </p>
      </div>

      {/* button section */}
      <div className="group flex items-center gap-2 ml-auto cursor-pointer">
        <motion.div
          whileTap={{ scale: 0.75 }}
          onClick={() => handleReduceItem(product)}
        >
          <BiMinus className="text-gray-50 " />
        </motion.div>

        <p className="w-5 h-5 rounded-sm bg-cartBg text-gray-50 flex items-center justify-center">
          {quantity}
        </p>

        <motion.div
          whileTap={{ scale: 0.75 }}
          onClick={() => handleAddProduct(product)}
        >
          <BiPlus className="text-gray-50 " />
        </motion.div>

        <motion.div
          whileTap={{ scale: 0.75 }}
          onClick={() => handleRemoveCartItem(documentID)}
        >
          <FaTrashAlt className="text-gray-50 " />
        </motion.div>
      </div>
    </div>
  );
};

export default ProductCart;
