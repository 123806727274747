import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { CountryDropdown } from "react-country-region-selector";
import { apiInstance } from "./../../Utils";
import {
  selectCartTotal,
  selectCartItemsCount,
  selectCartItems,
} from "./../../redux/Cart/cart.selectors";
import { saveOrderHistory } from "./../../redux/Orders/orders.actions";
import { createStructuredSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import { BsPinMapFill, BsFillInfoCircleFill } from "react-icons/bs";
import { SiOpenstreetmap } from "react-icons/si";
import { FaUser, FaMapMarkerAlt, FaMapMarker } from "react-icons/fa";
import Waiting from "../Waiting";
import Paiement from "../../img/stripe.webp";

const initialAddressState = {
  line1: "",
  line2: "",
  city: "",
  postal_code: "",
  country: "",
};

const mapState = createStructuredSelector({
  total: selectCartTotal,
  itemCount: selectCartItemsCount,
  cartItems: selectCartItems,
});

const PaymentDetails = () => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const { total, itemCount, cartItems } = useSelector(mapState);
  const dispatch = useDispatch();
  const [billingAddress, setBillingAddress] = useState({
    ...initialAddressState,
  });
  const [shippingAddress, setShippingAddress] = useState({
    ...initialAddressState,
  });
  const [recipientName, setRecipientName] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const [infoOrder, setInfoOrder] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  var livraison = 10;
  if (total > 150) {
    livraison = 0;
  }

  useEffect(() => {
    if (itemCount < 1) {
      history.push("/commandevalide");
    }
  }, [itemCount, history]);

  const handleShipping = (evt) => {
    const { name, value } = evt.target;
    setShippingAddress({
      ...shippingAddress,
      [name]: value,
    });
  };

  const handleBilling = (evt) => {
    const { name, value } = evt.target;
    setBillingAddress({
      ...billingAddress,
      [name]: value,
    });
  };

  const handleFormSubmit = async (evt) => {
    evt.preventDefault();
    setIsLoading(true);

    const cardElement = elements.getElement("card");

    if (
      !shippingAddress.line1 ||
      !shippingAddress.city ||
      !shippingAddress.postal_code ||
      !shippingAddress.country ||
      !billingAddress.line1 ||
      !billingAddress.city ||
      !billingAddress.postal_code ||
      !billingAddress.country ||
      !recipientName ||
      !nameOnCard
    ) {
      setIsLoading(false);
      return;
    }

    apiInstance
      .post("/payments/create", {
        amount: ((total + livraison) * 100).toFixed(0),
        shipping: {
          name: recipientName,
          address: {
            ...shippingAddress,
          },
        },
      })
      .then(({ data: clientSecret }) => {
        stripe
          .createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
              name: nameOnCard,
              address: {
                ...billingAddress,
              },
            },
          })
          .then(({ paymentMethod }) => {
            stripe
              .confirmCardPayment(clientSecret, {
                payment_method: paymentMethod.id,
              })
              .then(({ paymentIntent }) => {
                const configOrder = {
                  orderTotal: (total + livraison).toFixed(2),
                  orderInfo: infoOrder,
                  billingAddress: {
                    ...billingAddress,
                  },
                  shippingAddress: {
                    ...shippingAddress,
                  },
                  billingName: nameOnCard,
                  shippingName: recipientName,
                  orderItems: cartItems.map((item) => {
                    const { documentID, imageURL, title, price, quantity } =
                      item;

                    return {
                      documentID,
                      imageURL,
                      title,
                      price,
                      quantity,
                    };
                  }),
                };
                setIsLoading(false);
                dispatch(saveOrderHistory(configOrder));
              });
          });
      });
  };

  const configCardElement = {
    iconStyle: "solid",
    style: {
      base: {
        fontSize: "16px",
      },
    },
    hidePostalCode: true,
  };

  return (
    <div>
      <div className="w-full min-h-screen flex flex-col items-center justify-center pb-10">
        <div
          className="mt-8 w-[90%] md:w-[40%] border border-gray-300 
        rounded-lg p-4 flex flex-col items-center justify-center gap-4"
        >
          <button
            onClick={(e) => setIsLoading(true)}
            className="mt-1 text-2xl font-semibold text-bgFooterLow items-center justify-center "
          >
            Paiement
          </button>

          <form onSubmit={handleFormSubmit}>
            <div className="mt-8 text-xl font-medium text-bgFooterLow items-center justify-center">
              Adresse de livraison
            </div>
            <div
              className="w-full py-2 border-b border-x-gray-300 
                flex items-center gap-2"
            >
              <FaUser className="text-xl text-gray-700" />
              <input
                type="text"
                required
                name="recipientName"
                onChange={(e) => setRecipientName(e.target.value)}
                value={recipientName}
                placeholder="Nom Prénom du destinataire"
                className="w-full h-full text-lg bg-transparent
                    outline-none border-none placeholder:text-gray-400
                    text-textColor"
              />
            </div>
            <div
              className="w-full mt-4 py-2 border-b border-x-gray-300 
                flex items-center gap-2"
            >
              <BsPinMapFill className="text-xl text-gray-700" />
              <input
                type="text"
                required
                name="line1"
                onChange={(evt) => handleShipping(evt)}
                value={shippingAddress.line1}
                placeholder="Adresse"
                className="w-full h-full text-lg bg-transparent
                    outline-none border-none placeholder:text-gray-400
                    text-textColor"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              <div
                className="w-full py-2 border-b border-x-gray-300 
                    flex items-center gap-2"
              >
                <FaMapMarkerAlt className="text-xl text-gray-700" />
                <input
                  type="text"
                  required
                  name="city"
                  onChange={(evt) => handleShipping(evt)}
                  value={shippingAddress.city}
                  placeholder="Ville"
                  className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
                />
              </div>
              <div
                className="w-full py-2 border-b border-x-gray-300 
                    flex items-center gap-2"
              >
                <FaMapMarker className="text-xl text-gray-700" />
                <CountryDropdown
                  required
                  defaultOptionLabel="Pays"
                  onChange={(val) =>
                    handleShipping({
                      target: {
                        name: "country",
                        value: val,
                      },
                    })
                  }
                  value={shippingAddress.country}
                  valueType="short"
                  placeholder="Pays"
                  className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
                />
              </div>
            </div>
            <div
              className="w-full py-2 border-b border-x-gray-300 
                    flex items-center gap-2"
            >
              <SiOpenstreetmap className="text-xl text-gray-700" />
              <input
                required
                type="text"
                name="postal_code"
                onChange={(evt) => handleShipping(evt)}
                value={shippingAddress.postal_code}
                placeholder="Code postal"
                className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
              />
            </div>
            <div
              className="w-full py-2 border-b border-x-gray-300 
                    flex items-center gap-2"
            >
              <SiOpenstreetmap className="text-xl text-gray-700" />
              <input
                type="text"
                name="line2"
                onChange={(evt) => handleShipping(evt)}
                value={shippingAddress.line2}
                placeholder="Complément adresse (facultatif)"
                className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
              />
            </div>
            <div className="mt-8 text-xl font-medium text-bgFooterLow items-center justify-center">
              Adresse de facturation
            </div>
            <div
              className="w-full py-2 border-b border-x-gray-300 
                flex items-center gap-2"
            >
              <FaUser className="text-xl text-gray-700" />
              <input
                type="text"
                required
                name="nameOnCard"
                onChange={(evt) => setNameOnCard(evt.target.value)}
                value={nameOnCard}
                placeholder="Nom Prénom figurant sur la facture"
                className="w-full h-full text-lg bg-transparent
                    outline-none border-none placeholder:text-gray-400
                    text-textColor"
              />
            </div>
            <div
              className="w-full mt-4 py-2 border-b border-x-gray-300 
                flex items-center gap-2"
            >
              <BsPinMapFill className="text-xl text-gray-700" />
              <input
                type="text"
                required
                name="line1"
                onChange={(evt) => handleBilling(evt)}
                value={billingAddress.line1}
                placeholder="Adresse"
                className="w-full h-full text-lg bg-transparent
                    outline-none border-none placeholder:text-gray-400
                    text-textColor"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              <div
                className="w-full py-2 border-b border-x-gray-300 
                    flex items-center gap-2"
              >
                <FaMapMarkerAlt className="text-xl text-gray-700" />
                <input
                  type="text"
                  required
                  name="city"
                  onChange={(evt) => handleBilling(evt)}
                  value={billingAddress.city}
                  placeholder="Ville"
                  className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
                />
              </div>
              <div
                className="w-full py-2 border-b border-x-gray-300 
                    flex items-center gap-2"
              >
                <FaMapMarker className="text-xl text-gray-700" />
                <CountryDropdown
                  required
                  defaultOptionLabel="Pays"
                  onChange={(val) =>
                    handleBilling({
                      target: {
                        name: "country",
                        value: val,
                      },
                    })
                  }
                  value={billingAddress.country}
                  valueType="short"
                  placeholder="Pays"
                  className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
                />
              </div>
            </div>
            <div
              className="w-full py-2 border-b border-x-gray-300 
                    flex items-center gap-2"
            >
              <SiOpenstreetmap className="text-xl text-gray-700" />
              <input
                type="text"
                name="postal_code"
                onChange={(evt) => handleBilling(evt)}
                value={billingAddress.postal_code}
                placeholder="Code postal"
                className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
              />
            </div>
            <div
              className="w-full py-2 border-b border-x-gray-300 
                    flex items-center gap-2"
            >
              <SiOpenstreetmap className="text-xl text-gray-700" />
              <input
                type="text"
                name="line2"
                onChange={(evt) => handleBilling(evt)}
                value={billingAddress.line2}
                placeholder="Complément adresse (facultatif)"
                className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
              />
            </div>

            <div className="mt-8 text-xl font-medium text-bgFooterLow items-center justify-center">
              Information sur la livraison
            </div>
            <div
              className="w-full py-2 border-b border-x-gray-300 
                flex items-center gap-2"
            >
              <BsFillInfoCircleFill className="text-xl text-gray-700" />
              <input
                type="text"
                name="infoOrder"
                onChange={(evt) => setInfoOrder(evt.target.value)}
                value={infoOrder}
                placeholder="Information sur votre livraison"
                className="w-full h-full text-lg bg-transparent
                    outline-none border-none placeholder:text-gray-400
                    text-textColor"
              />
            </div>
            <div className="mt-8 text-xl font-medium text-bgFooterLow items-center justify-center">
              Détails de la carte
            </div>

            <CardElement options={configCardElement} className="mt-4" />
            <div className="mt-3">
              <img
                src={Paiement}
                alt="paiement sécurisé"
                className="mt-4 p-1 rounded-lg max-h-[120px] max-w-[240px] "
              />
            </div>
            <div className="flex mt-8 items-center justify-center w-full">
              <input
                type="submit"
                className="w-auto 
                    border-none outline-none bg-emerald-500 px-12 py-2 rounded-lg 
                    text-white font-semibold cursor-pointer "
                value="Payer maintenant"
              />
            </div>
          </form>
        </div>
      </div>
      {isLoading && <Waiting />}
    </div>
  );
};

export default PaymentDetails;
