import axios from "axios";

export const checkUserIsAdmin = (currentUser) => {
  if (!currentUser || !Array.isArray(currentUser.userRoles)) return false;
  const { userRoles } = currentUser;
  if (userRoles.includes("admin")) return true;

  return false;
};

export const apiInstance = axios.create({
  baseURL: "https://us-central1-site-edisco.cloudfunctions.net/api",
  //baseURL: "http://127.0.0.1:5001/site-edisco/us-central1/api",
});


