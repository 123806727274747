import React from "react";

const MainLayout = (props) => {
  return (
    <div className="w-screen h-auto flex flex-col bg-primary text-black">
      <div className="px-4 md:px-16  w-full">{props.children}</div>
    </div>
  );
};

export default MainLayout;
