import React from "react";
import SingleProduct from "./../../components/SingleProduct";

const ProductDetails = () => {
  return (
    <div>
      <SingleProduct />
    </div>
  );
};

export default ProductDetails;
