import React from "react";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const OrderValidate = () => {
  return (
    <div>
      <Helmet>
        <title>Edisco-CHL | Commande confirmé</title>
        <meta
          name="description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta name="keywords" content="grossiste dentaire" />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Edisco-CHL | Vente de Produits Dentaires"
        />
        <meta
          name="twitter:description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          property="og:description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          property="og:title"
          content="Edisco-CHL | Vente de Produits Dentaires"
        />
        <link rel="canonical" href="/commandevalide" />
      </Helmet>

      <div className="w-full text-base items-center text-bgFooter justify-between">
        <div className="text-base text-bgFooterLow font-semibold  ">
          Votre commande a été prise en compte. <br /> Merci de votre confiance.{" "}
          <br /> <br />
          L'équipe Edisco.
        </div>
      </div>

      <div className="mt-6 w-64 items-center justify-center text-base text-bgFooter ">
        <Link to={"/dashboard"}>
          <motion.div
            whileHover={{ scale: 1.07 }}
            className="items-center justify-center bg-gradient-to-tl from-blue-200 to-bgFooter px-4 py-1 rounded-full"
          >
            <p className="flex text-white justify-center items-center">
              Voir mes commandes
            </p>
          </motion.div>
        </Link>
      </div>
    </div>
  );
};

export default OrderValidate;
