import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getOrderDetailsStart } from "../../redux/Orders/orders.actions";
import { useDispatch, useSelector } from "react-redux";
import OrderDetails from "../../components/OrderDetails";

const mapState = ({ ordersData }) => ({
  orderDetails: ordersData.orderDetails,
});

const OrderAdmin = () => {
  const { orderID } = useParams();
  const dispatch = useDispatch();
  const { orderDetails } = useSelector(mapState);
  const {
    billingName,
    shippingName,
    orderTotal,
    orderInfo,
    email,
    phone,
    isPro,
    apeCode,
    company,
  } = orderDetails;
  const billingAddress = orderDetails && orderDetails.billingAddress;
  const shippingAddress = orderDetails && orderDetails.shippingAddress;

  useEffect(() => {
    dispatch(getOrderDetailsStart(orderID));
  }, [dispatch, orderID]);

  console.log(isPro);

  return (
    <div>
      <div className="text-xl mb-10 text-bgFooter relative">
        <span className="font-semibold ">Numéro de commande:</span> #{orderID}
      </div>

      <OrderDetails order={orderDetails} />

      <div className="text-l mt-4  text-bgFooter relative">
        <span className="font-semibold ">Email: </span> {email}
      </div>

      <div className="text-l mt-4  text-bgFooter relative">
        <span className="font-semibold ">Téléphone: </span> {phone}
      </div>

      {isPro && (
        <div>
          <div className="text-l mt-4  text-bgFooter relative">
            <span className="font-semibold ">Client professionnel : </span>
          </div>
          <div className="text-base ml-8  text-bgFooter relative">
            <p>
              <span className="font-medium">Code APE: </span> {apeCode}
            </p>
            <p>
              <span className="font-medium">Entreprise: </span> {company}
            </p>
          </div>
        </div>
      )}

      <div className="text-l mt-4  text-bgFooter relative">
        <span className="font-semibold ">Nom de facturation: </span>{" "}
        {billingName}
      </div>

      <div className="text-l mt-2  mb-10 text-bgFooter relative">
        <span className="font-semibold ">Adresse de facturation: </span>
        {billingAddress && (
          <div className="ml-8">
            <p>
              <span className="font-medium">Pays:</span>{" "}
              {billingAddress.country}
            </p>
            <p>
              <span className="font-medium">Ville:</span> {billingAddress.city}
            </p>
            <p>
              <span className="font-medium">Adresse: </span>
              {billingAddress.line1}
            </p>
            <p>
              <span className="font-medium">Complément d'adresse:</span>{" "}
              {billingAddress.line2}
            </p>
            <p>
              <span className="font-medium">Code postal:</span>{" "}
              {billingAddress.postal_code}
            </p>
          </div>
        )}
      </div>

      <div className="text-l mt-4  text-bgFooter relative">
        <span className="font-semibold ">Nom livraison: </span> {shippingName}
      </div>
      {billingAddress && (
        <div className="text-l mt-2  mb-10 text-bgFooter relative">
          <span className="font-semibold ">Adresse de livraison: </span>
          <div className="ml-8">
            <p>
              <span className="font-medium">Pays:</span>{" "}
              {shippingAddress.country}
            </p>
            <p>
              <span className="font-medium">Ville:</span> {shippingAddress.city}
            </p>
            <p>
              <span className="font-medium">Adresse: </span>
              {shippingAddress.line1}
            </p>
            <p>
              <span className="font-medium">Complément d'adresse:</span>{" "}
              {shippingAddress.line2}
            </p>
            <p>
              <span className="font-medium">Code postal:</span>{" "}
              {shippingAddress.postal_code}
            </p>
          </div>
        </div>
      )}
      <div className="text-l mt-4  mb-10 text-bgFooter relative">
        <span className="font-semibold ">Information sur la livraison : </span>{" "}
        {orderInfo ? orderInfo : <span>Aucune</span>}
      </div>
      <div className="mt-6 text-xl justify-center items-center text-bgFooter">
        <span className="font-semibold "> Total:</span> {orderTotal}{" "}
        <span className="text-xl text-bgFooterLow"> €</span>{" "}
        <span className="text-sm "> TTC</span>{" "}
        <span className="text-base "> (avec frais de port)</span>
      </div>
    </div>
  );
};

export default OrderAdmin;
