import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { RiRefreshFill } from "react-icons/ri";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import EmptyCart from "../../img/emptyCart.svg";
import ProductCart from "./../ProductCart";
import { showCart, showNoCart, clearCart } from "../../redux/Cart/cart.actions";

import { useHistory } from "react-router-dom";
import {
  selectCartItems,
  selectCartTotal,
} from "./../../redux/Cart/cart.selectors";
import { createStructuredSelector } from "reselect";

const mapState = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal,
});

const mapState2 = ({ user }) => ({
  currentUser: user.currentUser,
});

const CartContainer = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { cartItems, total } = useSelector(mapState);

  const { currentUser } = useSelector(mapState2);

  const setNoShowCart = () => {
    dispatch(showNoCart());
  };

  const clearAllItemsCart = () => {
    dispatch(clearCart());
    dispatch(showCart());
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: 400 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 400 }}
      className="fixed top-0 right-0 w-full md:w-375 h-screen bg-white drop-shadow-md flex flex-col z-[101]"
    >
      <div className="w-full flex items-center justify-between p-4 cursor-pointer">
        <motion.div whileTap={{ scale: 0.75 }} onClick={setNoShowCart}>
          <MdOutlineKeyboardBackspace className="text-textColor text-3xl" />
        </motion.div>
        <p className="text-textColor text-lg font-semibold">Panier</p>

        <motion.p
          whileTap={{ scale: 0.75 }}
          className="flex items-center gap-2 p-1 px-2 my-2 bg-gray-100 rounded-md hover:shadow-md  cursor-pointer text-textColor text-base"
          onClick={clearAllItemsCart}
        >
          Effacer <RiRefreshFill />
        </motion.p>
      </div>

      {/* bottom section */}
      {cartItems.length > 0 ? (
        <div className="w-full h-full bg-cartBg rounded-t-[2rem] flex flex-col">
          {/* cart Items section */}
          <div className="w-full h-340 md:h-42 px-6 py-10 flex flex-col gap-3 overflow-y-scroll scrollbar-none">
            {/* cart Item */}

            {cartItems &&
              cartItems.length > 0 &&
              cartItems.map((item, pos) => <ProductCart key={pos} {...item} />)}
          </div>

          {/* cart total section */}
          <div className="w-full flex-1 bg-cartTotal rounded-t-[2rem] flex flex-col items-center justify-evenly px-8 py-2">
            <div className="w-full flex items-center justify-between">
              <p className="text-gray-400 text-lg">
                Sous-total <span className="text-sm font-normal ">(TTC)</span>
              </p>
              <p className="text-gray-400 text-lg">
                {total.toFixed(2).toString().replace(".", ",")} €
              </p>
            </div>
            <div className="w-full flex items-center justify-between">
              <p className="text-gray-400 text-lg">
                Livraison <span className="text-sm font-normal ">(TTC)</span>
              </p>
              <p className="text-gray-400 text-lg">
                {total > 150 ? <p>Offert </p> : <p>10 € </p>}
              </p>
            </div>

            <div className="w-full border-b border-gray-600 my-2"></div>

            <div className="w-full flex items-center justify-between">
              <p className="text-gray-200 text-xl font-semibold">
                Total <span className="text-sm font-normal ">(TTC)</span>
              </p>
              <p className="text-gray-200 text-xl font-semibold">
                {total > 150 ? (
                  <p>{total.toFixed(2).toString().replace(".", ",")} €</p>
                ) : (
                  <p>
                    {(total + 10).toFixed(2).toString().replace(".", ",")} €
                  </p>
                )}
              </p>
            </div>

            {currentUser ? (
              <motion.button
                whileTap={{ scale: 0.8 }}
                type="button"
                onClick={() => history.push("/payment")}
                className="w-full p-2 rounded-full bg-gradient-to-tr from-blue-200 to-bgFooter text-gray-50 text-lg my-2 hover:shadow-lg"
              >
                Commander
              </motion.button>
            ) : (
              <Link to="/login">
                <motion.button
                  whileTap={{ scale: 0.8 }}
                  type="button"
                  className="w-full p-2 rounded-full bg-gradient-to-tr from-blue-200 to-bgFooter text-gray-50 text-lg my-2 hover:shadow-lg"
                >
                  Connectez-vous pour commander
                </motion.button>
              </Link>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex flex-col items-center justify-center gap-6">
          <img src={EmptyCart} className="w-300" alt="" />
          <p className="text-base text-textColor font-semibold">
            Vous n'avez aucun article dans votre panier.
          </p>
        </div>
      )}
    </motion.div>
  );
};

export default CartContainer;
