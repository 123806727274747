import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { signOutUserStart } from "../../redux/User/user.actions";
import { MdShoppingBasket, MdAdd, MdLogout } from "react-icons/md";
import { motion } from "framer-motion";

import Logo from "../../img/logo-removebg-preview.webp";
import Avatar from "../../img/avatar.webp";
import AvatarCo from "../../img/avatarCo.webp";
import { Link } from "react-router-dom";
import { TbSearch } from "react-icons/tb";
import { FaUserAlt } from "react-icons/fa";
import { useState } from "react";

import { checkUserIsAdmin } from "../../Utils";

import CartContainer from "../CartContainer";
import SearchContainer from "../SearchContainer";
import { showCart } from "../../redux/Cart/cart.actions";
import { showSearch } from "../../redux/Products/products.actions";

import { selectCartItemsCount } from "./../../redux/Cart/cart.selectors";

const mapState = (state) => ({
  currentUser: state.user.currentUser,
  cartShow: state.cartData.showCart,
  searchShow: state.productsData.showSearch,
  totalNumCartItems: selectCartItemsCount(state),
});

const Header = (props) => {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(signOutUserStart());
    setIsMenu(false);
  };

  const scrollSection = (idSection) =>
    window.scrollTo({
      top: document.getElementById(idSection).offsetTop - 130,
      behavior: "smooth",
    });

  const setShowCart = () => {
    dispatch(showCart());
  };

  const setShowSearch = () => {
    dispatch(showSearch());
  };

  const { currentUser, cartShow, searchShow, totalNumCartItems } =
    useSelector(mapState);
  const [isMenu, setIsMenu] = useState(false);

  return (
    <header className="fixed z-50 w-screen px-4  md:px-16 bg-primary">
      {/* desktop & table*/}
      <motion.div
        initial={{ opacity: 0, y: 75 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="hidden md:flex w-full h-full items-center justify-between"
      >
        <Link
          to="/"
          className="flex items-center gap-2"
          onClick={() => setIsMenu(false)}
        >
          <img
            src={Logo}
            alt="logo"
            className="w-24 min-h-[60px] min-w-[200px] object-cover"
          />
          <p className="text-bgFooter text-xl font-bold"></p>
        </Link>

        <div className="flex items-center gap-8">
          <motion.ul className="flex items-center gap-8">
            <Link to="/">
              <li
                className="text-base text-bgFooter hover:text-bgFooterLow duration-100 transition-all ease-in-out cursor-pointer"
                onClick={() => {
                  setIsMenu(false);
                  scrollSection("accueil");
                }}
              >
                Accueil
              </li>
            </Link>
            <Link to="/search">
              <li
                className="text-base text-bgFooter hover:text-bgFooterLow duration-100 transition-all ease-in-out cursor-pointer"
                onClick={() => {
                  setIsMenu(false);
                }}
              >
                Produits
              </li>
            </Link>
            <Link to="/apropos">
              <li
                className="text-base text-bgFooter hover:text-bgFooterLow duration-100 transition-all ease-in-out cursor-pointer"
                onClick={() => setIsMenu(false)}
              >
                A propos
              </li>
            </Link>
            <Link to="/contacter">
              <li
                className="text-base text-bgFooter hover:text-bgFooterLow duration-100 transition-all ease-in-out cursor-pointer"
                onClick={() => setIsMenu(false)}
              >
                Contacter
              </li>
            </Link>
          </motion.ul>

          <motion.button
            whileTap={{ scale: 0.9 }}
            className=""
            onClick={setShowSearch}
          >
            <TbSearch className=" text-bgFooter hover:text-bgFooterLow text-2xl cursor-pointer " />
          </motion.button>

          <div
            className="relative flex items-center justify-center"
            onClick={setShowCart}
          >
            <MdShoppingBasket className="text-bgFooter hover:text-bgFooterLow text-2xl cursor-pointer" />

            <div className="absolute -top-2 -right-2  w-5 h-5 rounded-full bg-cartNumBg flex items-center justify-center">
              <p className="text-xs text-white font-semibold">
                {totalNumCartItems}
              </p>
            </div>
          </div>
          <div className="relative flex items-center justify-center">
            {currentUser ? (
              <div className="relative flex items-center justify-center">
                {currentUser.photoURL ? (
                  <motion.img
                    whileTap={{ scale: 0.6 }}
                    whileHover={{ scale: 1.2 }}
                    src={currentUser.photoURL}
                    className="w-10 min-w-[40px] h-10 min-h-[40px] drop-shadow-xl cursor-pointer rounded-full"
                    alt="userprofile"
                    onClick={() => setIsMenu(!isMenu)}
                  />
                ) : (
                  <motion.img
                    whileTap={{ scale: 0.6 }}
                    whileHover={{ scale: 1.2 }}
                    src={AvatarCo}
                    className="w-10 min-w-[40px] h-10 min-h-[40px] drop-shadow-xl cursor-pointer rounded-full"
                    alt="userprofile"
                    onClick={() => setIsMenu(!isMenu)}
                  />
                )}
                <p
                  className="text-bgFooter cursor-pointer ml-2"
                  onClick={() => setIsMenu(!isMenu)}
                >
                  {currentUser?.displayName}
                </p>
              </div>
            ) : (
              <Link to="/login">
                <motion.img
                  whileTap={{ scale: 0.6 }}
                  src={Avatar}
                  className="w-10 min-w-[40px] h-10 min-h-[40px] drop-shadow-xl cursor-pointer rounded-full"
                  alt="userprofile"
                />
              </Link>
            )}
            {isMenu && (
              <motion.div
                initial={{ opacity: 0, scale: 0.6 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.6 }}
                className="w-40 bg-gray-50 shadow rounded-lg flex flex-col absolute top-12 right-0"
              >
                <Link to={"/addProduct"}>
                  {currentUser && checkUserIsAdmin(currentUser) && (
                    <p
                      className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-slate-100 transition-all duration-100 ease-in-out text-bgFooter
                                         text-base"
                      onClick={() => setIsMenu(false)}
                    >
                      Nouveau article
                      <MdAdd />
                    </p>
                  )}
                </Link>
                <Link to={"/admin"}>
                  {currentUser && checkUserIsAdmin(currentUser) && (
                    <p
                      className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-slate-100 transition-all duration-100 ease-in-out text-bgFooter
                                         text-base"
                      onClick={() => setIsMenu(false)}
                    >
                      Produits Admin
                    </p>
                  )}
                </Link>
                <Link to={"/userOrders"}>
                  {currentUser && checkUserIsAdmin(currentUser) && (
                    <p
                      className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-slate-100 transition-all duration-100 ease-in-out text-bgFooter
                                         text-base"
                      onClick={() => setIsMenu(false)}
                    >
                      Commandes utilisateurs
                    </p>
                  )}
                </Link>
                <Link to="/dashboard">
                  <p className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-slate-100 transition-all duration-100 ease-in-out text-bgFooter text-base">
                    {" "}
                    <FaUserAlt /> Mon compte{" "}
                  </p>
                </Link>

                <p
                  className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-slate-100 transition-all duration-100 ease-in-out text-bgFooter text-base"
                  onClick={logout}
                >
                  Déconnexion <MdLogout />
                </p>
              </motion.div>
            )}
          </div>
        </div>
      </motion.div>
      {/* mobile */}
      <div className="flex items-center justify-between md:hidden w-full h-full">
        <div className="relative flex items-center justify-center">
          <MdShoppingBasket
            className="text-textColor hover:text-bgFooterLow text-2xl cursor-pointer"
            onClick={setShowCart}
          />

          <div className="absolute -top-2 -right-2  w-5 h-5 rounded-full bg-cartNumBg flex items-center justify-center">
            <p className="text-xs text-white font-semibold">
              {totalNumCartItems}
            </p>
          </div>
        </div>

        <motion.button
          whileTap={{ scale: 0.9 }}
          className="ml-6 w-80 p-3 flex"
          onClick={setShowSearch}
        >
          <TbSearch className=" text-textColor hover:text-bgFooterLow text-2xl cursor-pointer " />
        </motion.button>

        <Link
          to="/"
          className="flex items-center justify-center px-6 gap-2"
          onClick={() => setIsMenu(false)}
        >
          <img
            src={Logo}
            alt="logo"
            className="w-20  min-h-[40px] min-w-[150px] object-cover"
          />
        </Link>

        <div className="relative items-center justify-center">
          {currentUser ? (
            <div className="relative flex items-center justify-center">
              {currentUser.photoURL ? (
                <motion.img
                  whileTap={{ scale: 0.6 }}
                  src={currentUser.photoURL}
                  className="w-10 min-w-[40px] h-10 min-h-[40px] drop-shadow-xl cursor-pointer rounded-full"
                  alt="userprofile"
                  onClick={() => setIsMenu(!isMenu)}
                />
              ) : (
                <motion.img
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.6 }}
                  src={AvatarCo}
                  className="w-10 min-w-[40px] h-10 min-h-[40px] drop-shadow-xl cursor-pointer rounded-full"
                  alt="userprofile"
                  onClick={() => setIsMenu(!isMenu)}
                />
              )}
            </div>
          ) : (
            <Link to="/login">
              <motion.img
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.6 }}
                src={Avatar}
                className="w-10 min-w-[40px] h-10 min-h-[40px] drop-shadow-xl cursor-pointer rounded-full"
                alt="userprofile"
              />
            </Link>
          )}
          {isMenu && (
            <motion.div
              initial={{ opacity: 0, scale: 0.6 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.6 }}
              className="w-40 bg-gray-50 shadow rounded-lg flex flex-col absolute top-12 right-0"
            >
              <Link to={"/addProduct"}>
                {currentUser && checkUserIsAdmin(currentUser) && (
                  <p
                    className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-slate-100 transition-all duration-100 ease-in-out text-textColor
                                         text-base"
                    onClick={() => setIsMenu(false)}
                  >
                    Nouveau article
                    <MdAdd />
                  </p>
                )}
              </Link>
              <Link to={"/admin"}>
                {currentUser && checkUserIsAdmin(currentUser) && (
                  <p
                    className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-slate-100 transition-all duration-100 ease-in-out text-textColor
                                         text-base"
                    onClick={() => setIsMenu(false)}
                  >
                    Produits Admin
                  </p>
                )}
              </Link>
              <Link to={"/userOrders"}>
                {currentUser && checkUserIsAdmin(currentUser) && (
                  <p
                    className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-slate-100 transition-all duration-100 ease-in-out text-textColor
                                         text-base"
                    onClick={() => setIsMenu(false)}
                  >
                    Commandes utilisateurs
                  </p>
                )}
              </Link>
              <ul className="flex flex-col">
                <Link to="/">
                  <li
                    className="text-base text-bgFooter  duration-100 transition-all ease-in-out cursor-pointer  hover:bg-slate-100 px-4 py-2"
                    onClick={() => {
                      setIsMenu(false);
                      scrollSection("accueil");
                    }}
                  >
                    Accueil
                  </li>
                </Link>
                <Link to="/search">
                  <li
                    className="text-base text-bgFooter  duration-100 transition-all ease-in-out cursor-pointer  hover:bg-slate-100 px-4 py-2"
                    onClick={() => {
                      setIsMenu(false);
                    }}
                  >
                    Produits
                  </li>
                </Link>
                <Link to="/apropos">
                  <li
                    className="text-base text-bgFooter  duration-100 transition-all ease-in-out cursor-pointer  hover:bg-slate-100 px-4 py-2"
                    onClick={() => setIsMenu(false)}
                  >
                    A propos
                  </li>
                </Link>
                <Link to="/contacter">
                  <li
                    className="text-base text-bgFooter  duration-100 transition-all ease-in-out cursor-pointer  hover:bg-slate-100 px-4 py-2"
                    onClick={() => setIsMenu(false)}
                  >
                    Contacter
                  </li>
                </Link>
              </ul>

              <Link to="/dashboard">
                <p className="px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-slate-100 transition-all duration-100 ease-in-out text-bgFooter text-base">
                  {" "}
                  <FaUserAlt />
                  Mon compte{" "}
                </p>
              </Link>

              <p
                className="m-2 p-2 rounded-md shadow-md flex items-center justify-center bg-gray-200 gap-3 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in-out text-bgFooter text-base"
                onClick={logout}
              >
                Déconnexion <MdLogout />
              </p>
            </motion.div>
          )}
        </div>
      </div>

      {searchShow && <SearchContainer />}

      {cartShow && <CartContainer />}
    </header>
  );
};

Header.defaultProps = {
  currentUser: null,
};

export default Header;
