import { firestore } from "./../../firebase/utils";
import { checkUserIsAdmin } from "../../Utils";

export const handleSaveOrder = (order) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("orders")
      .doc(`${Date.now()}`)
      .set(order)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleGetUserOrderHistory = (user) => {
  return new Promise((resolve, reject) => {
    let ref = firestore
      .collection("orders")
      .orderBy("orderCreatedDate", "desc");
    if (!checkUserIsAdmin(user)) {
      ref = ref.where("orderUserID", "==", user.id);
    }
    ref
      .get()
      .then((snap) => {
        const data = [
          ...snap.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({ data });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleGetOrder = (orderID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("orders")
      .doc(orderID)
      .get()
      .then((snap) => {
        if (snap.exists) {
          resolve({
            ...snap.data(),
            documentID: orderID,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleGetUserOrder = (order) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("users")
      .doc(order.orderUserID)
      .get()
      .then((snap) => {
        if (snap.exists) {
          resolve({
            ...order,
            phone: snap.data().phone,
            email: snap.data().email,
            isPro: snap.data().isPro,
            apeCode: snap.data().apeCode,
            company: snap.data().company,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
