import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { MdEmail } from "react-icons/md";

import { RiLockPasswordFill } from "react-icons/ri";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import { motion } from "framer-motion";

import {
  updateEmailUserStart,
  resetUserError,
} from "../../redux/User/user.actions";

import { auth, firestore } from "./../../firebase/utils";

import { signOutUserStart } from "../../redux/User/user.actions";

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
});

const Credentials = () => {
  const { currentUser } = useSelector(mapState);
  const dispatch = useDispatch();

  const [email, setEmail] = useState(currentUser?.email);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [fields, setFields] = useState(false);
  const [alertStatus, setAlertStatus] = useState("danger");
  const [msg, setMsg] = useState(null);

  const [fieldsDelete, setFieldsDelete] = useState(false);
  const [alertStatusDelete, setAlertStatusDelete] = useState("danger");
  const [msgDelete, setMsgDelete] = useState(null);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickVisibilityPassword = () => {
    setShowPassword(!showPassword);
  };

  const clearErrors = () => {
    dispatch(resetUserError());
  };

  const handleFormSubmitEmail = async (event) => {
    event.preventDefault();
    const userID = currentUser.id;
    try {
      auth.currentUser
        .updateEmail(email)
        .then(() => {
          dispatch(updateEmailUserStart({ userID, email }));
          setFields(true);
          setMsg("Changement de l'email réussi 😊");
          setAlertStatus("success");
          setTimeout(() => {
            setFields(false);
          }, 5000);
        })
        .catch((error) => {
          setFields(true);
          if (error.code === "auth/email-already-in-use") {
            setMsg("Cette email est déjà utilisée dans notre base de donnée");
          } else {
            setMsg(
              "Veuillez-vous déconnecter et reconnecter pour changer votre email"
            );
          }
          setAlertStatus("danger");
          setTimeout(() => {
            setFields(false);
          }, 5000);
        });
    } catch (err) {
      setFields(true);
      setMsg("Email erronée");
      setAlertStatus("danger");
      setTimeout(() => {
        setFields(false);
      }, 5000);
    }
  };

  const handleFormSubmitPassword = (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setFields(true);
      setMsg("Le mot de passe ne correspond pas");
      setAlertStatus("danger");
      setTimeout(() => {
        setFields(false);
      }, 5000);
    } else {
      auth.currentUser
        .updatePassword(password)
        .then(() => {
          clearErrors();
          setFields(true);
          setMsg("Changement de mot de passe réussi 😊");
          setAlertStatus("success");
          setTimeout(() => {
            setFields(false);
          }, 5000);
        })
        .catch((error) => {
          console.log(error);
          setFields(true);
          setMsg(
            "Veuillez-vous déconnecter et reconnecter pour changer votre mot de passe"
          );
          setAlertStatus("danger");
          setTimeout(() => {
            setFields(false);
          }, 5000);
        });
    }
  };

  const handleFormSubmitDelete = (event) => {
    event.preventDefault();

    firestore
    .collection("users")
    .doc(currentUser.id)
    .delete()
    .then(() => {
      console.log("Document successfully deleted!");
    })
    .catch((error) => {
      console.log("Error removing document: ", error);
    });

    auth.currentUser
      .delete()
      .then(() => {
        dispatch(signOutUserStart());
        setFieldsDelete(true);
        setMsgDelete("Suppresion du compte réussi 😊");
        setAlertStatusDelete("success");
        setTimeout(() => {
          setFields(false);
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
        setFieldsDelete(true);
        setMsgDelete(
          "Veuillez-vous déconnecter et reconnecter pour supprimer votre compte"
        );
        setAlertStatusDelete("danger");
        setTimeout(() => {
          setFields(false);
        }, 5000);
      });
  };

  return (
    <div>
      <form onSubmit={handleFormSubmitEmail}>
        {fields && (
          <div className="mb-4 flex justify-center items-center">
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={`w-full p-2 rounded-lg text-center text-lg font-semibold
                ${
                  alertStatus === "danger"
                    ? "bg-red-400 text-red-800"
                    : " bg-emerald-400 text-emerald-800"
                }`}
            >
              {msg}
            </motion.p>
          </div>
        )}
        <p className="mt-4 text-base">Email</p>
        <div
          className="w-full   p-2 rounded-md border border-gray-300 
                flex items-center gap-2"
        >
          <MdEmail className="text-xl text-gray-700" />
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={currentUser?.email}
            className="w-full h-full text-lg bg-transparent
                    outline-none border-none placeholder:text-gray-400
                    text-textColor"
          />
        </div>

        <div className="flex mt-4 items-center justify-center w-full">
          <button
            type="submit"
            className="text-white bg-gradient-to-br from-bgFooterLow to-bgFooter w-full 
                md:w-auto px-4 py-2 rounded-lg hover:shadow-lg transition-all ease-in-out 
                duration-100 text-base cursor-pointer "
          >
            Mettre à jour mon email
          </button>
        </div>
      </form>
      <form className="mt-8" onSubmit={handleFormSubmitPassword}>
        <div className=" grid grid-cols-1 md:grid-cols-2 gap-2">
          <div>
            <p className="mt-2 text-base">Mot de passe</p>
            <div
              className=" w-full p-2 rounded-md border border-gray-300 
                flex items-center gap-2"
            >
              <RiLockPasswordFill className="text-xl text-gray-700" />
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Mot de passe"
                className="w-full h-full text-lg bg-transparent
                    outline-none border-none placeholder:text-gray-400
                    text-textColor"
              />
              {showPassword ? (
                <AiOutlineEye
                  onClick={handleClickVisibilityPassword}
                  className="cursor-pointer text-xl text-gray-700"
                />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={handleClickVisibilityPassword}
                  className="cursor-pointer text-xl text-gray-700"
                />
              )}
            </div>
          </div>
          <div>
            <p className="mt-2 text-base">Confirmation Mot de passe</p>
            <div
              className=" w-full p-2 rounded-md border border-gray-300 
                flex items-center gap-2"
            >
              <RiLockPasswordFill className="text-xl text-gray-700" />
              <input
                type={showPassword ? "text" : "password"}
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirmation Mot de passe"
                className="w-full h-full text-lg bg-transparent
                    outline-none border-none placeholder:text-gray-400
                    text-textColor"
              />
              {showPassword ? (
                <AiOutlineEye
                  onClick={handleClickVisibilityPassword}
                  className="cursor-pointer text-xl text-gray-700"
                />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={handleClickVisibilityPassword}
                  className="cursor-pointer text-xl text-gray-700"
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex mt-4 items-center justify-center w-full">
          <button
            type="submit"
            className="text-white bg-gradient-to-br from-bgFooterLow to-bgFooter w-full 
                md:w-auto px-4 py-2 rounded-lg hover:shadow-lg transition-all ease-in-out 
                duration-100 text-base cursor-pointer "
          >
            Mettre à jour mon mot de passe
          </button>
        </div>
      </form>
      <form onSubmit={handleFormSubmitDelete}>
        <div className="mt-20 flex  items-center justify-center w-full">
          <label
            htmlFor="my-modal-3"
            className="text-white bg-gradient-to-br from-red-200 to-red-600 w-full 
                  md:w-auto px-4 py-2 rounded-lg hover:shadow-lg transition-all ease-in-out 
                  duration-100 text-base cursor-pointer "
          >
            Supprimer mon compte
          </label>
        </div>
        <input type="checkbox" id="my-modal-3" className="modal-toggle" />
        <div className="modal">
          <div className="modal-box relative bg-white">
            <label
              htmlFor="my-modal-3"
              className="btn btn-xs btn-circle border-none absolute right-2 top-2 bg-red-600"
            >
              ✕
            </label>
            <h3 className="text-lg  font-bold">
              Êtes-vous sure de vouloir supprimer votre compte.
            </h3>
            <p className="py-4 text-base">
              En supprimant votre compte, nous effaçons toutes vos données.
              Cette action est irréversible.
            </p>
            {fieldsDelete && (
          <div className="mb-4  flex justify-center items-center">
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={`w-full p-2 rounded-lg text-center text-lg font-semibold
                ${
                  alertStatusDelete === "danger"
                    ? "bg-red-400 text-red-800"
                    : " bg-emerald-400 text-emerald-800"
                }`}
            >
              {msgDelete}
            </motion.p>
          </div>
        )}
            <button
              type="submit"
              className="text-white bg-gradient-to-br from-red-200 to-red-600 w-full 
                  md:w-auto px-4 py-2 rounded-lg hover:shadow-lg transition-all ease-in-out 
                  duration-100 text-base cursor-pointer "
            >
              Supprimer mon compte
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Credentials;
