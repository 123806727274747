import React from "react";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { addProduct } from "../../redux/Cart/cart.actions";
import { showCart } from "../../redux/Cart/cart.actions";
import { MdShoppingBasket } from "react-icons/md";
import { motion } from "framer-motion";

const Product = (product) => {
  const dispatch = useDispatch();
  const { documentID, imageURL, title, description, price, reference } =
    product;
  if (
    !documentID ||
    !imageURL ||
    !title ||
    !description ||
    !reference ||
    typeof price === "undefined"
  )
    return null;

  const handleAddToCart = (product) => {
    if (!product) return;
    dispatch(addProduct(product));
    dispatch(showCart());
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: "75px" }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
      className="w-275 h-[200px] min-w-[275px] md:w-300 md:min-w-[300px]  bg-cardOverlay rounded-lg py-2 px-4  my-12 backdrop-blur-lg hover:drop-shadow-lg flex flex-col items-center justify-evenly relative"
    >
      <div className="w-full flex items-center justify-between">
        <motion.div
          className="w-24 h-24 -mt-8 drop-shadow-2xl"
          whileHover={{ scale: 1.2 }}
        >
          <Link to={`/produit/${documentID}`}>
            <img
              src={imageURL}
              alt={title}
              className="w-full h-full object-contain cursor-pointer"
            />
          </Link>
        </motion.div>
        <motion.div
          whileTap={{ scale: 0.75 }}
          className="w-8 h-8 rounded-full bg-bgFooter flex items-center justify-center cursor-pointer hover:shadow-md -mt-8"
          onClick={() => handleAddToCart(product)}
        >
          <MdShoppingBasket className="text-white" />
        </motion.div>
      </div>

      <div className="w-full flex flex-col items-end justify-end -mt-8">
        <p className="text-textColor font-semibold text-base md:text-lg">
          <Link to={`/produit/${documentID}`}>{title}</Link>
        </p>

        <div className="w-full  flex justify-between items-center gap-8">
          <p className="text-xs text-textColor font-semibold">
            <span className="text-smt">Ref:</span> {reference}
          </p>
          <p className="text-lg text-bgFooter font-semibold">
            {parseFloat(price.replace(",", "."))
              .toFixed(2)
              .toString()
              .replace(".", ",")}{" "}
            <span className="text-sm text-bgFooterLow">€</span>{" "}
            <span className="text-xs font-normal ">TTC</span>
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default Product;
