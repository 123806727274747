import { takeLatest, call, all } from "redux-saga/effects";
import newsletterTypes from "./newsletter.types";
import { handleAddEmailNewsletter } from "./newsletter.helpers";

export function* signUpNewsletter({ payload }) {
  try {
    yield handleAddEmailNewsletter({
      ...payload,
    });
  } catch (err) {
    // console.log(err);
  }
}

export function* onSignUpNewsletterStart() {
  yield takeLatest(newsletterTypes.SIGN_UP_NEWSLETTER_START, signUpNewsletter);
}

export default function* userSagas() {
  yield all([call(onSignUpNewsletterStart)]);
}
