import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserOrderHistory } from "./../../redux/Orders/orders.actions";
import OrderHistory from "./../../components/OrderHistory";

const mapState = ({ user, ordersData }) => ({
  currentUser: user.currentUser,
  orderHistory: ordersData.orderHistory.data,
});

const AdminCommandes = (props) => {
  const dispatch = useDispatch();
  const { currentUser, orderHistory } = useSelector(mapState);

  useEffect(() => {
    dispatch(getUserOrderHistory(currentUser));
  }, [dispatch, currentUser]);

  return (
    <div className="w-full h-auto flex flex-col items-center justify-center">
      <div className="w-full items-center justify-between">
        <p
          className="text-2xl mb-10 font-semibold text-bgFooter relative 
              before:absolute before:rounded-lg before:content before:w-20 before:h-1 
              before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-blue-200 to-bgFooter transition-all ease-in-out
              duration-100"
        >
          Commandes des utilisateurs
        </p>
      </div>
      <OrderHistory orders={orderHistory} />
    </div>
  );
};

export default AdminCommandes;
