import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from "./../../img/logo-removebg-preview.webp";
import { Link, useHistory } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import {
  resetPasswordStart,
  resetUserState,
  resetUserError,
} from "../../redux/User/user.actions";

const mapState = ({ user }) => ({
  resetPasswordSuccess: user.resetPasswordSuccess,
  userErr: user.userErr,
});

const EmailPassword = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { resetPasswordSuccess, userErr } = useSelector(mapState);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (resetPasswordSuccess) {
      dispatch(resetUserState);
      history.push("/login");
    }
  }, [resetPasswordSuccess, dispatch, history]);

  const clearErrors = () => {
    dispatch(resetUserError());
  };

  useEffect(() => {
    if (Array.isArray(userErr) && userErr.length > 0) {
      setErrors(userErr);
    }
  }, [userErr]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(resetPasswordStart({ email }));
  };

  return (
    <div className="w-full min-h-screen flex flex-col items-center justify-center">
      <Link to="/" className="flex items-center">
        <img
          src={Logo}
          alt="logo"
          className="w-60 min-h-[60px] min-w-[170px] object-cover"
        />
        <p className="text-bgFooter text-xl font-bold"></p>
      </Link>

      <div
        className="mt-8 w-[90%]  md:w-[50%] border border-gray-300 
                rounded-lg p-4 flex flex-col items-center justify-center gap-4"
      >
        <h1 className="mt-1 text-xl font-semibold text-bgFooterLow items-center justify-center ">
          Mot de passe oublié ?
        </h1>

        <h2 className="mt-1 text-base items-center justify-center ">
          Veuillez renseigner votre adresse email, nous vous enverrons un email
          avec un lien pour le mettre à jour.
        </h2>

        <form onSubmit={handleSubmit}>
          {errors.length > 0 && (
            <ul className="text-base items-center justify-center mt-6 text-red-500">
              {errors.map((e, index) => {
                return <li key={index}>{e}</li>;
              })}
            </ul>
          )}

          <div
            className="w-full mt-4  py-2 border-b border-x-gray-300 
                    flex items-center gap-2"
          >
            <MdEmail className="text-xl text-gray-700" />
            <input
              type="email"
              required
              name="email"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
            />
          </div>

          <div className="flex items-center mt-5 justify-center w-full">
            <input
              type="submit"
              className="w-auto 
                        border-none outline-none bg-emerald-500 px-12 py-2 rounded-lg 
                        text-white font-semibold cursor-pointer "
              value="Rénitialiser"
            />
          </div>
        </form>
        <h2 className="mt-1 text-base font-semibold items-center justify-center ">
          (Vérifier vos spams pour trouver l'email de rénitialisation)
        </h2>
        <Link to="/login" onClick={clearErrors}>
          <div className="mt-1 text-sm font-medium items-center justify-center cursor-pointer">
            Finalement, non !
          </div>
        </Link>
      </div>
    </div>
  );
};

export default EmailPassword;
