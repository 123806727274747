import React from "react";
import { useHistory } from "react-router-dom";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { checkUserIsAdmin } from "../../Utils";

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
});

const OrderHistory = ({ orders }) => {
  const history = useHistory();
  const { currentUser } = useSelector(mapState);

  function toDateTime(secs) {
    var t = new Date(1970, 0, 1);
    t.setSeconds(secs);
    let year = t.getFullYear();
    let month = t.getMonth() + 1;
    let day = t.getDate();
    return less10(day) + "-" + less10(month) + "-" + year;
  }
  function less10(time) {
    return time < 10 ? "0" + time : time;
  }

  return (
    <div>
      {Array.isArray(orders) && orders.length > 0 ? (
        <table className="table-fixed">
          <thead>
            <tr className="border-collapse  border border-blue-200">
              <th></th>
              <th>Date</th>
              <th>Numéro de commande</th>
              <th>Montant</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(orders) &&
              orders.length > 0 &&
              orders.map((row, index) => {
                const { orderCreatedDate, orderTotal, documentID } = row;

                return (
                  <tr
                    key={index}
                    className="border-collapse  border border-blue-200"
                  >
                    <td className="px-2 md:px-6 py-2">
                      <button
                        type="button"
                        className="bg-gradient-to-br from-blue-200 to-bgFooter w-full 
                        md:w-auto px-2 py-2 rounded-full hover:shadow-lg transition-all ease-in-out 
                        duration-100 text-base"
                        onClick={() => {
                          if (checkUserIsAdmin(currentUser)) {
                            history.push(`/userOrders/${documentID}`);
                          } else {
                            history.push(`/commande/${documentID}`);
                          }
                        }}
                      >
                        <BsFillInfoCircleFill />
                      </button>
                    </td>
                    <td className="px-2 md:px-6">
                      {toDateTime(orderCreatedDate.seconds)}
                    </td>
                    <td className=" px-2 md:px-6">{documentID}</td>
                    <td className=" px-2 md:px-6">
                      {orderTotal.replace(".", ",")} €{" "}
                      <span className="text-xs "> TTC</span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <div className="items-center justify-center">Aucune commande</div>
      )}
    </div>
  );
};

export default OrderHistory;
