import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { updateDataUserStart } from "../../redux/User/user.actions";

import {
  FaUser,
  FaMapMarkerAlt,
  FaMapMarker,
  FaPhoneAlt,
} from "react-icons/fa";
import { BsPinMapFill } from "react-icons/bs";
import { BsBuilding } from "react-icons/bs";
import { SiOpenstreetmap } from "react-icons/si";
import { TiSortNumericallyOutline } from "react-icons/ti";
import { motion } from "framer-motion";

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
});

const Profile = () => {
  const { currentUser } = useSelector(mapState);
  const dispatch = useDispatch();

  const [displayName, setDisplayName] = useState(currentUser?.displayName);
  const [address, setAddress] = useState(currentUser?.address);
  const [city, setCity] = useState(currentUser?.city);
  const [country, setCountry] = useState(currentUser?.country);
  const [phone, setPhone] = useState(currentUser?.phone);
  const [company, setCompany] = useState(currentUser?.company);
  const [apeCode, setApeCode] = useState(currentUser?.apeCode);
  const [addressComplement, setAddressComplement] = useState(
    currentUser?.addressComplement
  );
  const [fields, setFields] = useState(false);
  const [alertStatus, setAlertStatus] = useState("danger");
  const [msg, setMsg] = useState(null);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const userID = currentUser.id;
    try {
      dispatch(
        updateDataUserStart({
          userID,
          displayName,
          address,
          city,
          country,
          phone,
          company,
          apeCode,
          addressComplement,
        })
      );
      setFields(true);
      setMsg("Changement des données réussi 😊");
      setAlertStatus("success");
      setTimeout(() => {
        setFields(false);
      }, 5000);
    } catch (err) {
      setFields(true);
      setMsg("Changement des données échoué ");
      setAlertStatus("danger");
      setTimeout(() => {
        setFields(false);
      }, 5000);
    }
  };

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        {fields && (
          <div className="mb-4 flex justify-center items-center">
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={`w-full p-2 rounded-lg text-center text-lg font-semibold
                ${
                  alertStatus === "danger"
                    ? "bg-red-400 text-red-800"
                    : " bg-emerald-400 text-emerald-800"
                }`}
            >
              {msg}
            </motion.p>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div>
            <p className="text-base">Prénom Nom</p>
            <div
              className="w-full p-2 rounded-md border border-gray-300 
                    flex items-center gap-2"
            >
              <FaUser className="text-xl text-gray-700" />
              <input
                type="text"
                name="displayName"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                placeholder={currentUser?.displayName}
                className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
              />
            </div>
          </div>
          <div>
            <p className="text-base">Téléphone</p>
            <div
              className="w-full p-2 rounded-md border border-gray-300 
                    flex items-center gap-2"
            >
              <FaPhoneAlt className="text-xl text-gray-700" />
              <input
                type="tel"
                id="phone"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder={currentUser?.phone}
                className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
              />
            </div>
          </div>
        </div>
        {currentUser?.isPro && (
          <div className=" grid grid-cols-1 md:grid-cols-2 gap-2">
            <div>
              <p className="mt-2 text-base">Entreprise</p>
              <div
                className="w-full p-2 rounded-md border border-gray-300 
                              flex items-center gap-2"
              >
                <BsBuilding className="text-xl text-gray-700" />
                <input
                  type="text"
                  name="company"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  placeholder={currentUser?.company}
                  className="w-full h-full text-lg bg-transparent
                                  outline-none border-none placeholder:text-gray-400
                                  text-textColor"
                />
              </div>
            </div>
            <div>
              <p className="mt-2 text-base">Code APE</p>
              <div
                className="w-full p-2 rounded-md border border-gray-300 
                                          flex items-center gap-2"
              >
                <TiSortNumericallyOutline className="text-xl text-gray-700" />
                <input
                  type="text"
                  name="apeCode"
                  value={apeCode}
                  onChange={(e) => setApeCode(e.target.value)}
                  placeholder={currentUser?.apeCode}
                  className="w-full h-full text-lg bg-transparent
                                              outline-none border-none placeholder:text-gray-400
                                              text-textColor"
                />
              </div>
            </div>
          </div>
        )}

        <p className=" mt-2 text-base">Adresse</p>
        <div
          className="w-full p-2 rounded-md border border-gray-300 
                flex items-center gap-2"
        >
          <BsPinMapFill className="text-xl text-gray-700" />
          <input
            type="text"
            name="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder={currentUser?.address}
            className="w-full h-full text-lg bg-transparent
                    outline-none border-none placeholder:text-gray-400
                    text-textColor"
          />
        </div>

        <div className=" grid grid-cols-1 md:grid-cols-2 gap-2">
          <div>
            <p className=" mt-2 text-base">Ville</p>
            <div
              className="w-full p-2 rounded-md border border-gray-300 
                    flex items-center gap-2"
            >
              <FaMapMarkerAlt className="text-xl text-gray-700" />
              <input
                type="text"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder={currentUser?.city}
                className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
              />
            </div>
          </div>

          <div>
            <p className="mt-2 text-base">Pays</p>
            <div
              className="w-full p-2 rounded-md border border-gray-300 
                    flex items-center gap-2"
            >
              <FaMapMarker className="text-xl text-gray-700" />
              <input
                type="text"
                name="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                placeholder={currentUser?.country}
                className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
              />
            </div>
          </div>
        </div>
        <p className="mt-2 text-base">Complément d'adresse</p>
        <div
          className=" w-full p-2 rounded-md border border-gray-300 
                    flex items-center gap-2"
        >
          <SiOpenstreetmap className="text-xl text-gray-700" />
          <input
            type="text"
            name="addressComplement"
            value={addressComplement}
            onChange={(e) => setAddressComplement(e.target.value)}
            placeholder={currentUser?.addressComplement}
            className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
          />
        </div>
        <div className="flex mt-4 items-center justify-center w-full">
          <button
            type="submit"
            className="text-white bg-gradient-to-br from-bgFooterLow to-bgFooter w-full 
                md:w-auto px-4 py-2 rounded-lg hover:shadow-lg transition-all ease-in-out 
                duration-100 text-base cursor-pointer "
          >
            Mettre à jour mes données
          </button>
        </div>
      </form>
    </div>
  );
};

export default Profile;
