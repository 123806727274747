import { auth, firestore } from "./../../firebase/utils";

export const handleResetPasswordAPI = (email) => {
  const config = {
    url: "http://localhost:3000/recovery",
  };

  return new Promise((resolve, reject) => {
    auth
      .sendPasswordResetEmail(email, config)
      .then(() => {
        resolve();
      })
      .catch(() => {
        const err = ["Mail non trouvé. Veuillez réessayer."];
        reject(err);
      });
  });
};

export const handleUpdateDataUser = (
  userID,
  displayName,
  address,
  city,
  country,
  phone,
  company,
  apeCode,
  addressComplement
) => {
  return new Promise((resolve, reject) => {
    let sfDocRef = firestore.collection("users").doc(userID);
    firestore
      .runTransaction((transaction) => {
        return transaction.get(sfDocRef).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw new Error("Document does not exist!");
          }
          transaction.update(sfDocRef, {
            displayName: displayName,
            address: address,
            city: city,
            country: country,
            phone: phone,
            company: company,
            apeCode: apeCode,
            addressComplement: addressComplement,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const handleUpdateEmailUser = (userID, email) => {
  return new Promise((resolve, reject) => {
    let sfDocRef = firestore.collection("users").doc(userID);
    firestore
      .runTransaction((transaction) => {
        return transaction.get(sfDocRef).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw new Error("Document does not exist!");
          }
          transaction.update(sfDocRef, {
            email: email,
          });
        });
      })
      .catch((err) => {
        throw new Error("Email déjà utilisée ou erronée");
      });
  });
};
