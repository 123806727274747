import React from "react";
import HeroBg from "../../img/heroBg1.webp";
import { heroData } from "../../data/data";
import { Link } from "react-router-dom";
import { BsFillTelephoneFill } from "react-icons/bs";
import { motion } from "framer-motion";
import { RiSecurePaymentLine } from "react-icons/ri";
import { TbTruckDelivery } from "react-icons/tb";

const HomeContainer = (props) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 w-full" id="accueil">
      <motion.div
        initial={{ opacity: 0, x: "-100px" }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1.5 }}
        className="py-9  flex-1 flex flex-col items-start justify-center gap-6"
      >
        <h1 className="text-[2.5rem] lg:text-[4.25rem] font-bold tracking-wide text-titleColor">
          Vente de Produits{" "}
          <span className="text-bgFooter text-[3rem] lg:text-[5rem]">
            Dentaires{" "}
          </span>{" "}
          <span className=" text-[1.5rem] lg:text-[2.75rem] "> depuis </span>
          <span className="text-bgFooter text-[2rem] lg:text-[3.75rem] ">
            1963{" "}
          </span>
        </h1>

        <h2 className="text-base text-textColor text-center md:text-left md:w-[80%]">
          Produits et instruments destinés aux chirurgiens dentistes,
          prothésistes dentaires, laboratoires dentaires et particuliers.
        </h2>

        <div className="grid grid-cols-2 md:grid-cols-2 gap-2">
          <div className="flex items-center gap-2 justify-center bg-gradient-to-tl from-gray-200 to-gray-500 px-4 py-1 rounded-full text-base text-white font-semibold">
            Livraison rapide
            <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center hover:shadow-md ">
              <TbTruckDelivery className="text-colorGris w-6 h-6" />
            </div>
          </div>
          <div className="flex text-base text-white font-semibold items-center gap-2 justify-center bg-gradient-to-tl from-gray-200 to-gray-500 px-4 py-1 rounded-full">
            Paiement sécurisé
            <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center hover:shadow-md ">
              <RiSecurePaymentLine className="text-colorGris w-6 h-6" />
            </div>
          </div>
        </div>
        <div className="flex gap-2  text-center md:text-left  justify-center items-center">
          <h2 className="text-center text-bgFooter"> Contactez-nous</h2>
          <motion.div
            whileHover={{ scale: 1.07 }}
            className="flex items-center  justify-center bg-gradient-to-tl from-blue-200 to-bgFooter px-4 py-1 rounded-full"
          >
            <p className=" text-white  justify-center items-center">
              <a className="flex" href="tel:+33563782190">
                <BsFillTelephoneFill className="mr-2  font-semibold text-2xl cursor-pointer" />
                +33 6 73 53 78 38{" "}
              </a>
            </p>
          </motion.div>
        </div>
      </motion.div>
      <motion.div className="py-9 flex-1 flex items-center relative">
        <img
          src={HeroBg}
          className="ml-auto h-940 w-full lg:w-auto lg:h-650"
          alt="hero-bg"
        />

        <motion.div
          initial={{ opacity: 0, x: "100px" }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5 }}
          className="w-full h-full  absolute top-0 left-0 flex items-center justify-center 
                  py-4 gap-4 flex-wrap "
        >
          {heroData &&
            heroData.map((n) => (
              <motion.div
                whileHover={{ scale: 1.07 }}
                key={n.id}
                className="w-190 lg:w-190 p-4 bg-cardOverlay backdrop-blur-md rounded-3xl flex flex-col items-center justify-center  drop-shadow-lg"
              >
                <Link to={n.url}>
                  <img
                    src={n.imageSrc}
                    className="w-20  lg:w-40 -mt-10 lg:-mt-20 "
                    alt="I1"
                  />
                  <p className="text-base lg:text-xl font-semibold text-textColor mt-2 lg:mt-4">
                    {n.name}
                  </p>
                  <p className="text-[12px] lg:text-sm text-lighttextGray font-semibold my-1 lg:my-3">
                    {n.descp}
                  </p>
                  <p className="text-sm font-semibold text-bgFooter">
                    {" "}
                    {n.price}
                    <span className="text-xs text-bgFooterLow"> €</span>{" "}
                    <span className="text-xs text-lighttextGray  font-normal ">
                      TTC
                    </span>
                  </p>
                </Link>
              </motion.div>
            ))}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default HomeContainer;
