import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setOrderDetails } from "./../../redux/Orders/orders.actions";

const OrderDetails = ({ order }) => {
  const dispatch = useDispatch();
  const orderItems = order && order.orderItems;

  useEffect(() => {
    return () => {
      dispatch(setOrderDetails({}));
    };
  }, [dispatch]);

  return (
    <div>
      <div>
        <table className="table-fixed">
          <thead>
            <tr className="border-collapse  border border-blue-200">
              <th></th>
              <th>Produit</th>
              <th>Prix</th>
              <th>Quantité</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(orderItems) &&
              orderItems.length > 0 &&
              orderItems.map((row, index) => {
                const { title, price, quantity, imageURL } = row;

                return (
                  <tr
                    key={index}
                    className="border-collapse  border border-blue-200"
                  >
                    <td className="py-2 px-2">
                      <img className="w-36 m-auto" alt="produit" src={imageURL} />
                    </td>
                    <td className="px-6">{title}</td>
                    <td className="px-6">
                      {price} € <span className="text-xs "> TTC</span>
                    </td>
                    <td className="px-10">{quantity}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderDetails;
