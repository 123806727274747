import { firestore } from "./../../firebase/utils";
import { storage } from "../../firebase/utils";

export const handleAddProduct = (product) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("ediscoProduits")
      .doc(`${Date.now()}`)
      .set(product)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchProducts = ({
  allProducts,
  searchWord,
  filterType,
  startAfterDoc,
  persistProducts = [],
}) => {
  return new Promise((resolve, reject) => {
    var pageSize = 9;
    if (allProducts) {
      pageSize = 100;
    }

    if (searchWord) {
      let ref = firestore.collection("ediscoProduits").orderBy("id");
      if (startAfterDoc) {
        ref = ref.startAfter(startAfterDoc);

        ref.get().then((snapshot) => {
          snapshot = snapshot.docs.filter(
            (doc) =>
              doc
                .data()
                .title.toLowerCase()
                .includes(searchWord.toLowerCase()) +
              doc
                .data()
                .reference.toLowerCase()
                .includes(searchWord.toLowerCase())
          );

          var totalCount = 0;
          snapshot.map((doc) => totalCount++);

          const data = [
            ...persistProducts,
            ...snapshot.map((doc) => {
              return {
                ...doc.data(),
                documentID: doc.id,
              };
            }),
          ];

          resolve({
            data,
            queryDoc: snapshot[totalCount - 1],
            isLastPage: totalCount < 1,
          });
        });
      } else {
        ref.get().then((snapshot) => {
          snapshot = snapshot.docs.filter(
            (doc) =>
              doc
                .data()
                .title.toLowerCase()
                .includes(searchWord.toLowerCase()) +
              doc
                .data()
                .reference.toLowerCase()
                .includes(searchWord.toLowerCase())
          );

          var totalCount = 0;
          snapshot.map((doc) => totalCount++);

          const data = [
            ...persistProducts,
            ...snapshot.map((doc) => {
              return {
                ...doc.data(),
                documentID: doc.id,
              };
            }),
          ];

          resolve({
            data,
            queryDoc: snapshot[totalCount - 1],
            isLastPage: totalCount < 1,
          });
        });
      }
    } else {
      let ref = firestore
        .collection("ediscoProduits")
        .orderBy("id")
        .limit(pageSize);
      if (filterType) ref = ref.where("category", "==", filterType);
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

      ref
        .get()
        .then((snapshot) => {
          const totalCount = snapshot.size;

          const data = [
            ...persistProducts,
            ...snapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                documentID: doc.id,
              };
            }),
          ];

          resolve({
            data,
            queryDoc: snapshot.docs[totalCount - 1],
            isLastPage: totalCount < 1,
          });
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};

export const handleFetchProductsCateg = ({
  allProducts,
  searchWord,
  filterType,
  startAfterDoc,
  persistProducts = [],
}) => {
  return new Promise((resolve, reject) => {
    var pageSize = 9;
    if (allProducts) {
      pageSize = 100;
    }

    if (searchWord) {
      let ref = firestore.collection("ediscoProduits").orderBy("id");
      if (startAfterDoc) {
        ref = ref.startAfter(startAfterDoc);

        ref.get().then((snapshot) => {
          snapshot = snapshot.docs.filter(
            (doc) =>
              doc
                .data()
                .title.toLowerCase()
                .includes(searchWord.toLowerCase()) +
              doc
                .data()
                .reference.toLowerCase()
                .includes(searchWord.toLowerCase())
          );

          var totalCount = 0;
          snapshot.map((doc) => totalCount++);

          const data = [
            ...persistProducts,
            ...snapshot.map((doc) => {
              return {
                ...doc.data(),
                documentID: doc.id,
              };
            }),
          ];

          resolve({
            data,
            queryDoc: snapshot[totalCount - 1],
            isLastPage: totalCount < 1,
          });
        });
      } else {
        ref.get().then((snapshot) => {
          snapshot = snapshot.docs.filter(
            (doc) =>
              doc
                .data()
                .title.toLowerCase()
                .includes(searchWord.toLowerCase()) +
              doc
                .data()
                .reference.toLowerCase()
                .includes(searchWord.toLowerCase())
          );

          var totalCount = 0;
          snapshot.map((doc) => totalCount++);

          const data = [
            ...persistProducts,
            ...snapshot.map((doc) => {
              return {
                ...doc.data(),
                documentID: doc.id,
              };
            }),
          ];

          resolve({
            data,
            queryDoc: snapshot[totalCount - 1],
            isLastPage: totalCount < 1,
          });
        });
      }
    } else {
      let ref = firestore
        .collection("ediscoProduits")
        .orderBy("id")
        .limit(pageSize);
      if (filterType) ref = ref.where("category", "==", filterType);
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);

      ref
        .get()
        .then((snapshot) => {
          const totalCount = snapshot.size;
          const data = [
            ...persistProducts,
            ...snapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                documentID: doc.id,
              };
            }),
          ];

          resolve({
            data,
            queryDoc: snapshot.docs[totalCount - 1],
            isLastPage: totalCount < 1,
          });
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};

export const handleDeleteProduct = (documentID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("ediscoProduits")
      .doc(documentID)
      .get()
      .then((doc) => {
        const url = doc.data().imageURL;
        const deleteRef = storage.refFromURL(url);
        deleteRef.delete();
        firestore
          .collection("ediscoProduits")
          .doc(documentID)
          .delete()
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
  });
};

export const handleFetchProduct = (productID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("ediscoProduits")
      .doc(productID)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          resolve({
            ...snapshot.data(),
            documentID: productID,
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
