import React, { useState } from "react";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { ImCross } from "react-icons/im";
import { TbSearch } from "react-icons/tb";

import { showNoSearch } from "../../redux/Products/products.actions";

const SearchContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  var { searchWord } = useParams();

  const [word, setWord] = useState("");

  const setNoShowSearch = () => {
    dispatch(showNoSearch());
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    searchWord = word;
    const nextFilterWord = searchWord;
    history.push(`/search/${nextFilterWord}`);
    dispatch(showNoSearch());
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 200 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0, y: 200 }}
      className="fixed top-28 left-0 w-full h-80  drop-shadow-md flex justify-center items-center"
    >
      <div
        className="w-90 flex  items-center justify-center  p-4 cursor-pointer group  bg-card h-16 rounded-lg drop-shadow-lg 
             hover:bg-bgFooter duration-150 transition-all 
            ease-in-out'"
      >
        <motion.form
          onSubmit={handleFormSubmit}
          whileTap={{ scale: 0.9 }}
          className="w-80 p-3 flex"
        >
          <div className="mr-4 py-3 px-3 w-10 h-10 rounded-full bg-bgFooter group-hover:bg-card flex items-center justify-center">
            <TbSearch className="text-card group-hover:text-textColor text-lg " />
          </div>
          <input
            type="text"
            value={word}
            onChange={(e) => setWord(e.target.value)}
            className="outline-none w-full text-base border-b-2
                    border-gray-200 bg-white p-2 rounded-md cursor-pointer placeholder:text-black placeholder:text-base"
            placeholder="Rechercher..."
          ></input>
        </motion.form>
        <motion.p
          whileTap={{ scale: 0.75 }}
          className="flex items-center gap-2 p-1 px-2 my-2 group-hover:text-red-600  mr-2 rounded-full cursor-pointer text-textColor text-base"
          onClick={setNoShowSearch}
        >
          <ImCross className="text-lg " />
        </motion.p>
      </div>
    </motion.div>
  );
};

export default SearchContainer;
