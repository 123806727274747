import React from "react";

const LoadMore = ({ onLoadMoreEvt = () => {} }) => {
  return (
    <button
      type="button"
      className="mt-6 text-white bg-gradient-to-br from-bgFooterLow to-bgFooter w-full 
    md:w-auto px-4 py-2 rounded-lg hover:shadow-lg transition-all ease-in-out 
    duration-100 text-base"
      onClick={() => onLoadMoreEvt()}
    >
      Afficher plus
    </button>
  );
};

export default LoadMore;
