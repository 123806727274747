const productsTypes = {
  SET_SEARCH_SHOW: "SET_SEARCH_SHOW",
  ADD_NEW_PRODUCT_START: "ADD_NEW_PRODUCT_START",
  FETCH_PRODUCTS_START: "FETCH_PRODUCTS_START",
  FETCH_PRODUCTS_CATEG_START: "FETCH_PRODUCTS_CATEG_START",
  SET_PRODUCTS_CATEG: "SET_PRODUCTS_CATEG",
  SET_PRODUCTS: "SET_PRODUCTS",
  DELETE_PRODUCT_START: "DELETE_PRODUCT_START",
  FETCH_PRODUCT_START: "FETCH_PRODUCT_START",
  SET_PRODUCT: "SET_PRODUCT",
};

export default productsTypes;
