import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { checkUserSession } from "./redux/User/user.actions";

//hoc
import WithAuth from "./hoc/withAuth";
import WithAdminAuth from "./hoc/withAdminAuth";

//layout
import MainLayout from "./layouts/MainLayout";
import PageLayout from "./layouts/PageLayout";

// pages
import Homepage from "./pages/Homepage";
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import Recovery from "./pages/Recovery";
import Dashboard from "./pages/Dashboard";
import Admin from "./pages/Admin";
import AdminCommandes from "./pages/AdminCommandes";
import AddProduct from "./pages/AddProduct";
import ProductDetails from "./pages/ProductDetails";
import Payment from "./pages/Payment";
import ProduitsSearch from "./pages/ProduitsSearch";
import Order from "./pages/Order";
import OrderAdmin from "./pages/OrderAdmin";
import APropos from "./pages/APropos";
import Livraison from "./pages/Livraison";
import Contacter from "./pages/Contacter";
import Droits from "./pages/Droits";
import Politique from "./pages/Politique";
import OrderValidate from "./pages/OrderValidate";

const App = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUserSession());
  }, [dispatch]);

  const ScrollToTopAutomatic = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="app">
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <PageLayout>
              <Homepage />
            </PageLayout>
          )}
        />

        <Route
          exact
          path="/produits"
          render={() => (
            <PageLayout>
              <Homepage />
            </PageLayout>
          )}
        />

        <Route
          path="/livraison"
          render={() => (
            <PageLayout>
              {ScrollToTopAutomatic()}
              <Livraison />
            </PageLayout>
          )}
        />

        <Route
          path="/commandevalide"
          render={() => (
            <PageLayout>
              {ScrollToTopAutomatic()}
              <OrderValidate />
            </PageLayout>
          )}
        />

        <Route
          path="/apropos"
          render={() => (
            <PageLayout>
              {ScrollToTopAutomatic()}
              <APropos />
            </PageLayout>
          )}
        />

        <Route
          path="/droits"
          render={() => (
            <PageLayout>
              {ScrollToTopAutomatic()}
              <Droits />
            </PageLayout>
          )}
        />

        <Route
          path="/politique-de-confidentialite"
          render={() => (
            <PageLayout>
              {ScrollToTopAutomatic()}
              <Politique />
            </PageLayout>
          )}
        />

        <Route
          path="/contacter"
          render={() => (
            <PageLayout>
              {ScrollToTopAutomatic()}
              <Contacter />
            </PageLayout>
          )}
        />

        <Route
          path="/produits/:filterType"
          render={() => (
            <PageLayout>
              <Homepage />
            </PageLayout>
          )}
        />

        <Route
          exact
          path="/search"
          render={() => (
            <PageLayout>
              {ScrollToTopAutomatic()}
              <ProduitsSearch />
            </PageLayout>
          )}
        />

        <Route
          path="/search/:searchWord"
          render={() => (
            <PageLayout>
              {ScrollToTopAutomatic()}
              <ProduitsSearch />
            </PageLayout>
          )}
        />

        <Route
          path="/searchbycategory/:filterType"
          render={() => (
            <PageLayout>
              {ScrollToTopAutomatic()}
              <ProduitsSearch />
            </PageLayout>
          )}
        />

        <Route
          path="/produit/:id"
          render={() => (
            <PageLayout>
              {ScrollToTopAutomatic()}
              <ProductDetails />
            </PageLayout>
          )}
        />

        <Route
          path="/registration"
          render={() => (
            <MainLayout>
              <Registration />
            </MainLayout>
          )}
        />

        <Route
          path="/login"
          render={() => (
            <MainLayout>
              <Login />
            </MainLayout>
          )}
        />

        <Route
          path="/recovery"
          render={() => (
            <MainLayout>
              <Recovery />
            </MainLayout>
          )}
        />

        <Route
          path="/payment"
          render={() => (
            <WithAuth>
              <MainLayout>
                {ScrollToTopAutomatic()}
                <Payment />
              </MainLayout>
            </WithAuth>
          )}
        />

        <Route
          path="/dashboard"
          render={() => (
            <WithAuth>
              <PageLayout>
                {ScrollToTopAutomatic()}
                <Dashboard />
              </PageLayout>
            </WithAuth>
          )}
        />

        <Route
          path="/commande/:orderID"
          render={() => (
            <WithAuth>
              {ScrollToTopAutomatic()}
              <PageLayout>
                <Order />
              </PageLayout>
            </WithAuth>
          )}
        />

        <Route
          path="/admin"
          render={() => (
            <WithAdminAuth>
              <PageLayout>
                {ScrollToTopAutomatic()}
                <Admin />
              </PageLayout>
            </WithAdminAuth>
          )}
        />

        <Route
          exact
          path="/userOrders"
          render={() => (
            <WithAdminAuth>
              <PageLayout>
                {ScrollToTopAutomatic()}
                <AdminCommandes />
              </PageLayout>
            </WithAdminAuth>
          )}
        />

        <Route
          path="/userOrders/:orderID"
          render={() => (
            <WithAdminAuth>
              {ScrollToTopAutomatic()}
              <PageLayout>
                <OrderAdmin />
              </PageLayout>
            </WithAdminAuth>
          )}
        />

        <Route
          path="/addProduct"
          render={() => (
            <WithAdminAuth>
              <PageLayout>
                {ScrollToTopAutomatic()}
                <AddProduct />
              </PageLayout>
            </WithAdminAuth>
          )}
        />
      </Switch>
    </div>
  );
};

export default App;
