import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  emailSignInStart,
  resetUserError,
} from "../../redux/User/user.actions";
import Logo from "./../../img/logo-removebg-preview.webp";
import { Link, useHistory } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import Waiting from "../Waiting";

import { RiLockPasswordFill } from "react-icons/ri";

import ReCAPTCHA from "react-google-recaptcha";
import { apiInstance } from "./../../Utils";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
  userErr: user.userErr,
});

const SignIn = (props) => {
  const { currentUser, userErr } = useSelector(mapState);
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState([]);

  const captchaRef = useRef(null);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickVisibilityPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (currentUser) {
      resetForm();
      //clearErrors()
      history.push("/");
    }
  }, [currentUser, history]);

  useEffect(() => {
    if (Array.isArray(userErr) && userErr.length > 0) {
      setIsLoading(false);
      setErrors(userErr);
    }
  }, [userErr]);

  const resetForm = () => {
    setEmail("");
    setPassword("");
    setErrors([]);
  };

  const clearErrors = () => {
    dispatch(resetUserError());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const token = captchaRef.current.getValue();

    if (token) {
      let valid_token = await verifyToken(token);
      if (valid_token.success) {
        captchaRef.current.reset();
        dispatch(emailSignInStart({ email, password }));
      } else {
        setErrors(["Désolé ! Token invalide"]);
        setIsLoading(false);
      }
    } else {
      setErrors(["Vous devez confirmer que vous n'êtes pas un robot"]);
      setIsLoading(false);
    }
  };

  const verifyToken = async (token) => {
    try {
      let response = await apiInstance.post("/verify-token", {
        secret: process.env.REACT_APP_SECRET_KEY,
        token,
      });
      return response.data;
    } catch (error) {
      console.log("error ", error);
    }
  };

  return (
    <div>
      {isLoading && <Waiting />}

      <div className="w-full min-h-screen flex flex-col items-center justify-center pb-10">
        <Link to="/" className="flex items-center">
          <img
            src={Logo}
            alt="logo"
            className="w-60 min-h-[60px] min-w-[170px] object-cover"
          />
          <p className="text-bgFooter text-xl font-bold"></p>
        </Link>

        <div
          className="mt-8 w-[90%]  md:w-[50%] border border-gray-300 
                    rounded-lg p-4 flex flex-col items-center justify-center gap-4"
        >
          <div className="mt-1 text-xl font-semibold text-bgFooterLow items-center justify-center ">
            Connexion
          </div>

          <form onSubmit={handleSubmit}>
            {errors.length > 0 && (
              <ul className="text-base items-center justify-center mt-6 text-red-500">
                {errors.map((e, index) => {
                  return <li key={index}>{e}</li>;
                })}
              </ul>
            )}

            <div
              className="w-full  py-2 border-b border-x-gray-300 
                        flex items-center gap-2"
            >
              <MdEmail className="text-xl text-gray-700" />
              <input
                type="email"
                required
                name="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                className="w-full h-full text-lg bg-transparent
                            outline-none border-none placeholder:text-gray-400
                            text-textColor"
              />
            </div>

            <div
              className="mt-3 w-full py-2 border-b border-x-gray-300 
                        flex items-center gap-2"
            >
              <RiLockPasswordFill className="text-xl text-gray-700" />
              <input
                type={showPassword ? "text" : "password"}
                required
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Mot de passe"
                className="w-full h-full text-lg bg-transparent
                            outline-none border-none placeholder:text-gray-400
                            text-textColor"
              />
              {showPassword ? (
                <AiOutlineEye
                  onClick={handleClickVisibilityPassword}
                  className="cursor-pointer text-xl text-gray-700"
                />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={handleClickVisibilityPassword}
                  className="cursor-pointer text-xl text-gray-700"
                />
              )}
            </div>

            <Link to="/recovery" onClick={clearErrors}>
              <div className="mt-1 text-sm font-medium items-center justify-center cursor-pointer">
                Mot de passe oublié ?
              </div>
            </Link>

            <ReCAPTCHA
              className="mt-2 items-center justify-center"
              sitekey={process.env.REACT_APP_SITE_KEY}
              ref={captchaRef}
            />

            <div className="mt-6 flex items-center justify-center w-full">
              <input
                type="submit"
                className="w-auto 
                        border-none outline-none bg-emerald-500 px-12 py-2 rounded-lg 
                        text-white font-semibold cursor-pointer "
                value="Connexion"
              />
            </div>
          </form>

          <div className="text-sm items-center justify-center">
            En vous connectant, vous acceptez nos{" "}
            <a
              className="cursor-pointer font-semibold decoration-solid "
              target="_blank"
              href="/droits"
            >
              Conditions générales de vente{" "}
            </a>{" "}
            et notre{" "}
            <a
              className="cursor-pointer font-semibold decoration-solid "
              target="_blank"
              href="/politique-de-confidentialite"
            >
              Politique de confidentialité
            </a>
            .
          </div>
        </div>
        <div className="mt-6 text-base font-bold cursor-pointer">
          Nouveau sur Edisco ?
        </div>
        <Link to="/registration">
          <button
            type="button"
            className=" mt-2 bg-gradient-to-br   from-blue-200 to-bgFooter w-full 
                        md:w-auto px-4 py-2 cursor-pointer rounded-lg hover:shadow-lg transition-all ease-in-out 
                        duration-100 text-base text-white"
          >
            Créez votre compte Edisco
          </button>
        </Link>
      </div>
    </div>
  );
};

export default SignIn;
