import React, { useEffect } from "react";
// import { motion } from "framer-motion";
// import { MdChevronLeft, MdChevronRight } from "react-icons/md";
// import ProductsVertical from "../ProductsVertical";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProductStart,
  setProduct,
} from "./../../redux/Products/products.actions";
import { addProduct } from "./../../redux/Cart/cart.actions";
import { showCart } from "./../../redux/Cart/cart.actions";

const mapState = (state) => ({
  product: state.productsData.product,
});

const SingleProduct = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { product } = useSelector(mapState);

  const {
    //documentID,
    imageURL,
    title,
    description,
    price,
    //category,
    //qty,
    reference,
  } = product;

  // const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {
    dispatch(fetchProductStart(id));

    return () => {
      dispatch(setProduct({}));
    };
  }, [dispatch, id]);

  const handleAddToCart = (product) => {
    if (!product) return;
    dispatch(addProduct(product));
    dispatch(showCart());
  };

  // useEffect(() => {}, [scrollValue]);

  return (
    <section className="w-full my-6 md:my-6">
      <div className="px-2 md:px-40 items-center justify-center grid grid-cols-1 md:grid-cols-2 gap-2">
        <div>
          <img src={imageURL} className="w-80" alt="" />
        </div>
        <div className="mt-8">
          <h1 className="text-2xl font-semibold capitalize text-bgFooter relative">
            {title}
          </h1>
          <h2 className="mt-6  text-4xl justify-center items-center font-semibold text-bgFooter">
            {" "}
            {price} <span className=" text-bgFooterLow"> €</span>{" "}
            <span className="text-sm font-normal ">TTC</span>{" "}
          </h2>
          <div className="text-base mt-8">
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <div className="mt-8 flex items-center ">
            {/* 
                    
                    {() => {
                        if (qty < 1) {
                            return (<p className='text-3xl font-semibold text-red-500'>Indisponible</p>)
                        }else {
                            return (
                                <motion.div 
                                whileTap={{scale:0.9}}
                                className='w-20'>
                                    <select
                                    className="outline-none w-full text-base border-b-2
                                    border-gray-200 p-2 rounded-md cursor-pointer"> 
                                    {() => {
                                    for (let i= 1; i<=qty ; i++){
                                        return(                               
                                            <option className="text-base border-0 
                                                outline-none capitalize bg-white text-bgFooterLow"
                                                value={i} 
                                                >
                                            {i}
                                            </option>
                                        )
                                    }}}
                                    </select>
                                </motion.div>
                            )
                        }
                    }}
*/}

            <div>
              <h2 className=" text-base">Ref: {reference}</h2>
            </div>
          </div>
          <div className="flex items-center">
            <button
              onClick={() => handleAddToCart(product)}
              className="mt-8  p-3 text-base rounded-md bg-bgFooter text-white hover:bg-bgFooterLow"
            >
              Ajouter au panier
            </button>
          </div>
        </div>
      </div>

      {/* 
        <div className='w-full my-16 md:my-16'>
            <div className='w-full flex items-center justify-between'>
            <p className='text-2xl font-semibold  text-bgFooter relative 
            before:absolute before:rounded-lg before:content before:w-20 before:h-1 
            before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-blue-200 to-bgFooter transition-all ease-in-out
            duration-100'>
                Produits de même catégorie
            </p>

            <div className='hidden md:flex gap-3 items-center'>
                <motion.div 
                whileTap={{ scale: 0.75 }} 
                className='w-8 h-8 rounded-lg bg-blue-300 hover:bg-bgFooterLow cursor-pointer 
                transition-all duration-100 ease-in-out hover:shadow-lg flex items-center 
                justify-center'
                onClick={ () => setScrollValue(-200)}
                >
                <MdChevronLeft className='text-lg text-white'/>
                </motion.div>
                <motion.div 
                whileTap={{ scale: 0.75 }} 
                className='w-8 h-8 rounded-lg bg-blue-300 hover:bg-bgFooterLow cursor-pointer 
                transition-all duration-100 ease-in-out hover:shadow-lg flex items-center 
                justify-center'
                onClick={() => setScrollValue(200)}
                >
                <MdChevronRight className='text-lg text-white'/>
                </motion.div>
            </div>
            </div><ProductsVertical
            scrollValue={scrollValue}
            categ ={category}
            />
      </div>
*/}
    </section>
  );
};

export default SingleProduct;
