import React from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Helmet } from "react-helmet-async";

const Contacter = () => {
  return (
    <div>
      <Helmet>
        <title>Edisco-CHL | Nous contacter</title>
        <meta
          name="description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta name="keywords" content="grossiste dentaire" />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Edisco-CHL | Vente de Produits Dentaires"
        />
        <meta
          name="twitter:description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          property="og:description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          property="og:title"
          content="Edisco-CHL | Vente de Produits Dentaires"
        />
        <link rel="canonical" href="/contacter" />
      </Helmet>
      <div className="w-full items-center justify-between">
        <h1
          className="text-2xl mb-10 font-semibold text-bgFooter relative 
            before:absolute before:rounded-lg before:content before:w-20 before:h-1 
            before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-blue-200 to-bgFooter transition-all ease-in-out
            duration-100"
        >
          Nous contacter
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 ">
        <div className="mb-5 ml-8">
          <ul className="text-bgFooter text-base py-6">
            <li className="pb-6 flex items-center">
              <FaMapMarkerAlt className="mr-2 text-bgFooterLow text-2xl" />2 Rue
              de Quarin, 81160 Saint-Juéry, <br /> France
            </li>
            <li className="pb-4 items-center">
              <a className="flex" href="tel:+33563782190">
                <BsFillTelephoneFill className="mr-2 text-bgFooterLow text-2xl cursor-pointer" />{" "}
                +33 5 63 78 21 90{" "}
              </a>
            </li>
            <li className="pb-4 flex items-center">
              <a className="flex" href="tel:+33673537838">
                <BsFillTelephoneFill className="mr-2 text-2xl text-bgFooterLow cursor-pointer" />
                +33 6 73 53 78 38{" "}
              </a>
            </li>
            <li className="pb-4 flex items-center">
              <a className="flex" href="mailto:info@edisco-chl.com">
                <GrMail className="mr-3 text-2xl text-bgFooterLow cursor-pointer" />
                info@edisco-chl.com
              </a>
            </li>
          </ul>
        </div>
        <div className="mb-5">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2873.0873817289544!2d2.2048236999999995!3d43.9368652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12ade8be056033f7%3A0xd4bb7d675d7f28e8!2sEdisco!5e0!3m2!1sfr!2sfr!4v1665989633960!5m2!1sfr!2sfr"
            width="500"
            height="350"
            alt="edisco carte"
            title="carteEdisco"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contacter;
