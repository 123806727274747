import userTypes from "./user.types";

const INITIAL_STATE = {
  currentUser: null,
  resetPasswordSuccess: false,
  userErr: [],
};

const INITIAL_STATE_ERROR = {
  userErr: [],
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        userErr: [],
      };
    case userTypes.UPDATE_USER_DATA:
      const dataUser = action.payload;
      return {
        ...state,
        ...INITIAL_STATE_ERROR,
        currentUser: {
          address: dataUser.address,
          addressComplement: dataUser.addressComplement,
          apeCode: dataUser.apeCode,
          city: dataUser.city,
          company: dataUser.company,
          country: dataUser.country,
          createdDate: state.currentUser.createdDate,
          displayName: dataUser.displayName,
          email: state.currentUser.email,
          id: state.currentUser.id,
          isPro: state.currentUser.isPro,
          phone: dataUser.phone,
          userRoles: state.currentUser.userRoles,
        },
      };
      case userTypes.UPDATE_USER_EMAIL:
        const emailUpdate = action.payload.email;
        return {
          ...state,
          ...INITIAL_STATE_ERROR,
          currentUser: {
            address: state.currentUser.address,
            addressComplement: state.currentUser.addressComplement,
            apeCode: state.currentUser.apeCode,
            city: state.currentUser.city,
            company: state.currentUser.company,
            country: state.currentUser.country,
            createdDate: state.currentUser.createdDate,
            displayName: state.currentUser.displayName,
            email: emailUpdate,
            id: state.currentUser.id,
            isPro: state.currentUser.isPro,
            phone: state.currentUser.phone,
            userRoles: state.currentUser.userRoles,
          },
        };
    case userTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: action.payload,
      };
    case userTypes.USER_ERROR:
      return {
        ...state,
        userErr: action.payload,
      };
    case userTypes.RESET_USER_STATE:
    case userTypes.SIGN_OUT_USER_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case userTypes.RESET_USER_ERROR:
      return {
        ...state,
        ...INITIAL_STATE_ERROR,
      };
    default:
      return state;
  }
};

export default userReducer;
