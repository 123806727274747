import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProductsStart,
  deleteProductStart,
} from "./../../redux/Products/products.actions";
import LoadMore from "./../../components/LoadMore";
import { FaTrashAlt } from "react-icons/fa";

const mapState = ({ productsData }) => ({
  products: productsData.products,
});

const Admin = (props) => {
  const { products } = useSelector(mapState);
  const dispatch = useDispatch();

  const { data, queryDoc, isLastPage } = products;

  useEffect(() => {
    dispatch(fetchProductsStart());
  }, [dispatch]);

  const handleLoadMore = () => {
    dispatch(
      fetchProductsStart({
        startAfterDoc: queryDoc,
        persistProducts: data,
      })
    );
  };

  const configLoadMore = {
    onLoadMoreEvt: handleLoadMore,
  };

  return (
    <div className="w-full items-center justify-between">
      <p
        className="text-2xl font-semibold  text-bgFooter relative 
                before:absolute before:rounded-lg before:content before:w-20 before:h-1 
                before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-blue-200 to-bgFooter transition-all ease-in-out
                duration-100"
      >
        Panneau d'admistration
      </p>
      <div>
        <table border="0" cellPadding="0" cellSpacing="0">
          <tbody>
            <tr>
              <th>
                <h1 className="mt-6 mb-2">Gérer les produits</h1>
              </th>
            </tr>
            <tr>
              <td>
                <table
                  className="bg-blue-50 rounded-lg  "
                  border="0"
                  cellPadding="10"
                  cellSpacing="0"
                >
                  <tbody>
                    {Array.isArray(data) &&
                      data.length > 0 &&
                      data.map((product, index) => {
                        const {
                          title,
                          imageURL,
                          price,
                          category,
                          description,
                          qty,
                          reference,
                          documentID,
                        } = product;

                        return (
                          <tr
                            key={index}
                            className="border-collapse  border border-blue-200"
                          >
                            <td className="w-36 px-6 py-2">
                              <img className="w-36 m-auto" alt="produit" src={imageURL} />
                            </td>
                            <td className="px-6 py-2">
                              {title} <br />
                              <br />
                              Catégorie: {category}
                            </td>
                            <td className="px-6 py-2">{description}</td>
                            <td className="w-36 px-6 py-2">
                              {price} € <br />
                              Ref: {reference} <br />
                              Stock: {qty}
                            </td>
                            <td className="px-6 py-2">
                              <button
                                type="button"
                                className="bg-gradient-to-br from-red-400 to-red-600 w-full 
                                        md:w-auto px-4 py-2 rounded-lg hover:shadow-lg transition-all ease-in-out 
                                        duration-100 text-base"
                                onClick={() =>
                                  dispatch(deleteProductStart(documentID))
                                }
                              >
                                <FaTrashAlt />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td>
                <table border="0" cellPadding="10" cellSpacing="0">
                  <tbody>
                    <tr>
                      <td>{!isLastPage && <LoadMore {...configLoadMore} />}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Admin;
