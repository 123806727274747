import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import LoadMore from "../LoadMore";
import { fetchProductsStart } from "../../redux/Products/products.actions";

import NotFound from "../../img/NotFound.svg";
import Product from "../Product";

import { motion } from "framer-motion";

import { TbDental } from "react-icons/tb";
import { categories } from "../../data/data";


const mapState = (state) => ({
  currentUser: state.user.currentUser,
  products: state.productsData.products,
});

const SearchProducts = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { searchWord,filterType } = useParams();

  const [categ, setCateg] = useState("");

  const { currentUser, products } = useSelector(mapState);

  const { data, queryDoc, isLastPage } = products;

  useEffect(() => {
    dispatch(fetchProductsStart({ searchWord,filterType }));
  }, [searchWord, filterType, dispatch]);

  const handleFilterSearch = (e) => {
    const nextFilterWord = e.target.value;
    history.push(`/search/${nextFilterWord}`);
  };

  const handleFilter = (e) => {
    const nextFilter = e.target.value;
    setCateg(e.target.value);
    history.push(`/searchbycategory/${nextFilter}`);
  };

  useEffect(() => {
    if (searchWord!== "") {
      setCateg("");
    }
  }, [searchWord]);

  const handleLoadMore = () => {
    dispatch(
      fetchProductsStart({
        searchWord,
        filterType,
        startAfterDoc: queryDoc,
        persistProducts: data,
      })
    );
  };

  const configLoadMore = {
    onLoadMoreEvt: handleLoadMore,
  };

  if (!Array.isArray(data)) return null;
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <div className="w-full mt-4 items-center justify-center py-6 gap-6 md:gap-8 grid grid-cols-1 md:grid-cols-3 ">
        <div
          className="group col-span-1 bg-card w-auto md:min-w-[480px] md:max-w-[480px] h-16 rounded-lg drop-shadow-lg 
          flex items-center justify-center hover:bg-bgFooter duration-150 transition-all 
          ease-in-out"
        >
          <div className="ml-4 py-3 px-3 w-10 h-10 rounded-full bg-bgFooter group-hover:bg-card flex items-center justify-center">
            <TbDental className="text-card group-hover:text-textColor text-lg " />
          </div>
          <motion.div whileTap={{ scale: 0.9 }} className="w-full p-3">
            <select
              onChange={handleFilter}
              value={categ}
              className="outline-none w-full text-base border-b-2
                        border-gray-200 bg-white p-2 rounded-md cursor-pointer"
            >
              {categories &&
                categories.map((item) => (
                  <option
                    key={item.id}
                    className="text-base border-0 
                                outline-none capitalize bg-white text-bgFooterLow"
                    value={item.urlParamName}
                  >
                    {item.name}
                  </option>
                ))}
            </select>
          </motion.div>
          <motion.div whileTap={{ scale: 0.9 }} className="w-full p-3 flex">
            <input
              type="text"
              value={searchWord}
              onChange={handleFilterSearch}
              className="outline-none w-full text-base border-b-2
                  border-gray-200 bg-white p-2 rounded-md cursor-pointer placeholder:text-black placeholder:text-base"
              placeholder="Rechercher..."
            ></input>
          </motion.div>
        </div>
      </div>
      {searchWord && data.length > 1 && (
        <div
          className="text-xl items-center  text-bgFooter relative 
      transition-all ease-in-out
    duration-100 mr-auto flex "
        >
          <p>
            Résultat de la recherche pour :{" "}
            <span className="text-2xl items-center font-semibold ml-1">
              {searchWord}
            </span>
          </p>
        </div>
      )}

      {data.length < 1 ? (
        <div className="flex flex-col justify-center items-center">
          <img src={NotFound} className="h-340" alt="aucun resultat" />
          <p className="text-xl  text-bgFooterLow font-semibold my-2">
            Aucun résultat de recherche.
          </p>
        </div>
      ) : (
        <div
          className={
            "w-full flex items-center gap-3  my-12 scroll-smooth overflow-x-hidden flex-wrap justify-center"
          }
        >
          {data.map((product, pos) => {
            const { imageURL, title, description, price, pro } = product;
            if (
              !imageURL ||
              !title ||
              !description ||
              typeof price === "undefined"
            )
              return null;

            const configProduct = {
              ...product,
            };
            if (pro) {
              if (currentUser?.isPro) {
                return <Product key={pos} {...configProduct} />;
              }
              return null;
            }
            return <Product key={pos} {...configProduct} />;
          })}
        </div>
      )}

      {!isLastPage && <LoadMore {...configLoadMore} />}
    </div>
  );
};

export default SearchProducts;
