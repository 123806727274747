import React from "react";
import CreateContainer from "../../components/CreateContainer";

const AddProduct = (props) => {
  return (
    <section>
      <CreateContainer />
    </section>
  );
};

export default AddProduct;
