import React from "react";

import Products from "../Products";

const ProductsContainer = (props) => {
  return (
    <div className="w-full my-6 mt-15 md:mt-0">
      <div className="w-full flex flex-col items-center justify-center">
        <h1
          className="text-2xl font-semibold capitalize text-bgFooter relative 
            before:absolute before:rounded-lg before:content before:w-16 before:h-1 
            before:-bottom-2 before:left-0   before:bg-gradient-to-tr from-blue-200 to-bgFooter transition-all ease-in-out
            duration-100 mr-auto"
        >
          Nos produits
        </h1>

        <div className="w-full h-auto">
          <Products />
        </div>
      </div>
    </div>
  );
};

export default ProductsContainer;
