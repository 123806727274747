import { firestore } from './../../firebase/utils';


export const handleAddEmailNewsletter = email => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('newsletter')
      .doc(`${Date.now()}`)
      .set(email)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      })
  });
}




