import productsTypes from "./products.types";

export const showSearch = () => ({
  type: productsTypes.SET_SEARCH_SHOW,
  payload: true,
});

export const showNoSearch = () => ({
  type: productsTypes.SET_SEARCH_SHOW,
  payload: false,
});

export const addProductStart = (productData) => ({
  type: productsTypes.ADD_NEW_PRODUCT_START,
  payload: productData,
});

export const fetchProductsStart = (filters = {}) => ({
  type: productsTypes.FETCH_PRODUCTS_START,
  payload: filters,
});

export const fetchProductsCategStart = (filters = {}) => ({
  type: productsTypes.FETCH_PRODUCTS_CATEG_START,
  payload: filters,
});

export const setProducts = (products) => ({
  type: productsTypes.SET_PRODUCTS,
  payload: products,
});

export const setProductsCateg = (productsCateg) => ({
  type: productsTypes.SET_PRODUCTS_CATEG,
  payload: productsCateg,
});

export const deleteProductStart = (productID) => ({
  type: productsTypes.DELETE_PRODUCT_START,
  payload: productID,
});

export const fetchProductStart = (productID) => ({
  type: productsTypes.FETCH_PRODUCT_START,
  payload: productID,
});

export const setProduct = (product) => ({
  type: productsTypes.SET_PRODUCT,
  payload: product,
});
