import { React, useState } from "react";
import { MdChevronRight } from "react-icons/md";
import { motion } from "framer-motion";
import {
  BsFacebook,
  BsInstagram,
  BsFillTelephoneFill,
  BsLinkedin,
} from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { signUpNewsletterStart } from "../../redux/Newsletter/newsletter.actions";
import { useDispatch } from "react-redux";
import Paiement from "../../img/stripe.webp";

//Mettre le menu avec les 4 objets
const Footer = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [fields, setFields] = useState(false);
  const [msg, setMsg] = useState(null);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setFields(true);
    setMsg("Inscription réussi 😊");

    dispatch(
      signUpNewsletterStart({
        email,
      })
    );

    setTimeout(() => {
      setFields(false);
    }, 4000);

    setEmail("");
  };
  return (
    <section>
      <div className="p-10 bg-gradient-to-bl from-colorGris to-colorGris text-white">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 ">
            <div className="mb-5">
              <h4
                className="text-2xl font-semibold capitalize text-bgFooter relative 
                            before:absolute before:rounded-lg before:content before:w-20 before:h-1 
                            before:-bottom-2 before:left-0 before:bg-gradient-to-tl from-blue-200 to-bgFooter transition-all ease-in-out
                            duration-100"
              >
                Nous contacter
              </h4>
              <ul className="text-white text-base py-6">
                <li className="pb-6 flex items-center">
                  <FaMapMarkerAlt className="mr-2 text-2xl" />2 Rue de Quarin,
                  81160 Saint-Juéry, <br /> France
                </li>
                <li className="pb-4 items-center">
                  <motion.a whileHover={{ scale: 1.07 }} className="flex" href="tel:+33563782190">
                    <BsFillTelephoneFill className="mr-2 text-2xl cursor-pointer" />{" "}
                    +33 5 63 78 21 90{" "}
                  </motion.a>
                </li>
                <li className="pb-4 flex items-center">
                  <motion.a whileHover={{ scale: 1.07 }} className="flex" href="tel:+33673537838">
                    <BsFillTelephoneFill className="mr-2 text-2xl cursor-pointer" />
                    +33 6 73 53 78 38{" "}
                  </motion.a>
                </li>
                <li className="pb-4 flex items-center">
                  <a className="flex" href="mailto:info@edisco-chl.com">
                    <GrMail className="mr-3 text-2xl cursor-pointer" />
                    info@edisco-chl.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="mb-5">
              <h4
                className="text-2xl font-semibold capitalize text-bgFooter relative 
                            before:absolute before:rounded-lg before:content before:w-20 before:h-1 
                            before:-bottom-2 before:left-0 before:bg-gradient-to-tl from-blue-200 to-bgFooter transition-all ease-in-out
                            duration-100"
              >
                Navigation
              </h4>
              <ul className="text-white text-base py-6">
                <Link to="/search">
                  <motion.li
                    whileHover={{ scale: 1.05 }}
                    className="pb-4 hover:text-bgFooter duration-100 transition-all ease-in-out cursor-pointer flex items-center"
                  >
                    <MdChevronRight />
                    Nos produits
                  </motion.li>
                </Link>
                <Link to="/droits">
                  <motion.li
                    whileHover={{ scale: 1.05 }}
                    className="pb-4 hover:text-bgFooter duration-100 transition-all ease-in-out cursor-pointer flex items-center"
                  >
                    <MdChevronRight />
                    Conditions de vente
                  </motion.li>
                </Link>
                <Link to="/politique-de-confidentialite">
                  <motion.li
                    whileHover={{ scale: 1.05 }}
                    className="pb-4 hover:text-bgFooter duration-100 transition-all ease-in-out cursor-pointer flex items-center"
                  >
                    <MdChevronRight />
                    Politique de confidentialité
                  </motion.li>
                </Link>
                <Link to="/contacter">
                  <motion.li
                    whileHover={{ scale: 1.05 }}
                    className="pb-4 hover:text-bgFooter duration-100 transition-all ease-in-out cursor-pointer flex items-center"
                  >
                    <MdChevronRight />
                    Nous contacter
                  </motion.li>
                </Link>
                <Link to="/apropos">
                  <motion.li
                    whileHover={{ scale: 1.05 }}
                    className="pb-4 hover:text-bgFooter duration-100 transition-all ease-in-out cursor-pointer flex items-center"
                  >
                    <MdChevronRight />A propos
                  </motion.li>
                </Link>
                <Link to="/livraison">
                  <motion.li
                    whileHover={{ scale: 1.05 }}
                    className="pb-4 hover:text-bgFooter duration-100 transition-all ease-in-out cursor-pointer flex items-center"
                  >
                    <MdChevronRight />
                    Livraison
                  </motion.li>
                </Link>
              </ul>
            </div>
            <div className="mb-5">
              <h4
                className="text-2xl font-semibold capitalize text-bgFooter relative 
                            before:absolute before:rounded-lg before:content before:w-20 before:h-1 
                            before:-bottom-2 before:left-0 before:bg-gradient-to-tl from-blue-200 to-bgFooter transition-all ease-in-out
                            duration-100"
              >
                Newsletter
              </h4>
              <p className="text-white text-base py-6">
                Recevez nos nouveaux produits
              </p>

              {fields && (
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className={
                    "w-full p-2 rounded-lg text-center text-lg font-semibold bg-emerald-400 text-emerald-800"
                  }
                >
                  {msg}
                </motion.p>
              )}

              <form
                onSubmit={handleFormSubmit}
                className="flex flex-row flex-wrap"
              >
                <input
                  type="email"
                  required
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-2/3 rounded-l-md bg-white text-bgFooter p-2  placeholder:text-base"
                  placeholder="email@example.com"
                />
                <button className="p-2 w-1/3 text-base rounded-r-md bg-bgFooter text-white hover:text-bgFooter hover:bg-gray-200">
                  Soumettre
                </button>
              </form>
              <div className="text-xs mt-1">
                En inscrivant votre mail, vous acceptez notre{" "}
                <motion.a
                  whileHover={{ scale: 1.07 }}
                  className="cursor-pointer underline font-semibold decoration-solid "
                  target="_blank"
                  href="/politique-de-confidentialite"
                >
                  Politique de confidentialité
                </motion.a>
                .
              </div>

              <div className="mt-6">
                <h4
                  className="text-2xl font-semibold capitalize text-bgFooter relative 
                            before:absolute before:rounded-lg before:content before:w-20 before:h-1 
                            before:-bottom-2 before:left-0 before:bg-gradient-to-tl from-blue-200 to-bgFooter transition-all ease-in-out
                            duration-100"
                >
                  Paiement sécurisé Stripe
                </h4>
                <img
                  src={Paiement}
                  alt="paiement sécurisé"
                  className="mt-4 p-1 rounded-lg border-2 border-bgFooter max-h-[120px] max-w-[240px] "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-bgFooter text-gray-200 px-10">
        <div className="md:ml-12 max-w-7xl flex flex-col sm:flex-row py-4 mx-auto justify-between items-center">
          <div className="text-center">
            <div>
              ©{" "}
              <a className="font-semibold" href="/">
                Edisco
              </a>
              ,{" "}
              <a className="cursor-pointer " href="/droits">
                Tous droits réservés, 2022.
              </a>
            </div>
            <div className="text-center flex ">
              Développé par{" "}
              <a
                className="text-base ml-1 sm:ml-2 flex text-white font-semibold hover:text-colorGris"
                href="https://www.linkedin.com/in/anthony-rodrigo/"
                rel="noreferrer"
                target="_blank"
              >
                {" "}
                <BsLinkedin className="text-2xl flex mr-1 sm:mr-2" /> Anthony
                Rodrigo
              </a>
            </div>
          </div>
          <div className="text-center text-xl text-white mb-2 mt-3 md:mr-12">
            <a
              href="https://fr.linkedin.com/company/comptoir-du-haut-languedoc?trk=public_profile_experience-item_profile-section-card_subtitle-click"
              target="_blank"
              rel="noreferrer"
              className="w-10 h-10 rounded-full bg-white hover:bg-colorGris  inline-block  cursor-pointer hover:shadow-md mx-3 "
            >
              <BsLinkedin className="ml-2.5 text-bgFooter mt-2.5" />{" "}
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100078871472735"
              target="_blank"
              rel="noreferrer"
              className="w-10 h-10 rounded-full bg-white hover:bg-colorGris  inline-block items-center justify-center cursor-pointer hover:shadow-md mx-3  "
            >
              {" "}
              <BsFacebook className="ml-2.5 text-bgFooter mt-2.5" />
            </a>
            <a
              href="https://www.instagram.com/edisco_chl/?hl=fr"
              target="_blank"
              rel="noreferrer"
              className="w-10 h-10 rounded-full bg-white hover:bg-colorGris  inline-block items-center justify-center cursor-pointer hover:shadow-md mx-3 "
            >
              {" "}
              <BsInstagram className="ml-2.5 text-bgFooter mt-2.5" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
