import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getOrderDetailsStart } from "./../../redux/Orders/orders.actions";
import { useDispatch, useSelector } from "react-redux";
import OrderDetails from "./../../components/OrderDetails";

const mapState = ({ ordersData }) => ({
  orderDetails: ordersData.orderDetails,
});

const Order = () => {
  const { orderID } = useParams();
  const dispatch = useDispatch();
  const { orderDetails } = useSelector(mapState);
  const { orderTotal } = orderDetails;

  console.log(orderTotal);

  useEffect(() => {
    dispatch(getOrderDetailsStart(orderID));
  }, [dispatch, orderID]);

  return (
    <div>
      <div className="text-xl mb-10 text-bgFooter relative">
        <span className="font-semibold ">Numéro de commande:</span> #{orderID}
      </div>

      <OrderDetails order={orderDetails} />

      <div className="mt-8 text-xl justify-center items-center text-bgFooter">
        <span className="font-semibold "> Total:</span> {orderTotal}{" "}
        <span className="text-xl text-bgFooterLow"> €</span>{" "}
        <span className="text-sm "> TTC</span>{" "}
        <span className="text-base "> (avec frais de port)</span>
      </div>
    </div>
  );
};

export default Order;
