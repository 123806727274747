import React from "react";
import { Helmet } from "react-helmet-async";

const Droits = () => {
  return (
    <div>
      <Helmet>
        <title>Edisco-CHL | Conditions générales de vente d’Edisco</title>
        <meta
          name="description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta name="keywords" content="grossiste dentaire" />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Edisco-CHL | Vente de Produits Dentaires"
        />
        <meta
          name="twitter:description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          property="og:description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          property="og:title"
          content="Edisco-CHL | Vente de Produits Dentaires"
        />
        <link rel="canonical" href="/droits" />
      </Helmet>
      <div className="w-full items-center justify-between">
        <h1
          className="text-2xl mb-10 font-semibold text-bgFooter relative 
            before:absolute before:rounded-lg before:content before:w-20 before:h-1 
            before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-blue-200 to-bgFooter transition-all ease-in-out
            duration-100"
        >
          Conditions générales de vente d’Edisco
        </h1>
      </div>

      <div className="w-full text-base items-center text-bgFooter justify-between">
        <h2 className="text-xl text-bgFooterLow font-semibold  ">
          Catalogue des produits et services :
        </h2>
        <p className="mt-2">
          Edisco situé Domaine de Montplaisir 81160 - Saint-Juéry France SIRET
          404.394.553.00048(96B82) propose la vente de produits et services à
          partir d'un catalogue accessible sur Internet.
        </p>
        <h2 className="text-xl mt-8 text-bgFooterLow font-semibold  ">
          Commandes :
        </h2>
        <p className="mt-2">
          Le client choisit à l'aide du catalogue électronique les produits et
          services qu'il désire acquérir et transmet sa commande à Edisco à
          partir d'un bon de commande disponible sur le site à valider suivant
          les instructions.
        </p>
        <h2 className="text-xl mt-8 text-bgFooterLow font-semibold  ">
          Prix de vente :
        </h2>
        <p className="mt-2">
          Les prix des produits et services facturés sont ceux figurant sur le
          catalogue électronique au moment de la commande. Les prix de vente
          s'entendent en prix net. Une participation aux frais de livraison, si
          elle est demandée, est mentionnée clairement sur le bordereau de
          validation finale ainsi que le montant total à payer.
        </p>
        <h2 className="text-xl text-bgFooterLow font-semibold  mt-8">
          Délai de livraison :
        </h2>
        <p className="mt-2">
          Les produits et services sont normalement livrés par Colissimo à
          l'adresse indiquée par le client dans un délai de 3 à 5 jours
          ouvrables. L'expéditeur se réserve le droit d'utiliser tout autre
          moyen de livraison dans le respect des délais et frais prévus. Les
          délais de livraison peuvent varier en cas de difficultés
          d'acheminement, de rupture de stocks ou périodes de vacances qui
          seront alors signalés par courrier électronique au client. Les jours
          ouvrables ne comprennent pas les week-end et jours fériés. Pour les
          livraisons internationales, en fonction des produits et des régions,
          les délais peuvent varier de plusieurs semaines.
        </p>
        <h2 className="text-xl text-bgFooterLow font-semibold mt-8 ">
          Frais de livraison :
        </h2>
        <p className="mt-2">
          Gratuit à partir de 150 €, sinon une participation forfaitaire de 10 €
          sera ajoutée à votre commande.
        </p>
        <h2 className="text-xl text-bgFooterLow font-semibold  mt-8">
          Réserves :
        </h2>
        <p className="mt-2">
          Le client devra s'assurer du bon état de la livraison. En cas de
          dommages constatés ou supposés, il appartient au client d'émettre des
          réserves auprès du livreur et d'en informer Edisco par lettre
          recommandée AR dans un délai maximum de 48 heures.
        </p>
        <h2 className="text-xl text-bgFooterLow font-semibold mt-8  ">
          Non-disponibilité :
        </h2>
        <p className="mt-2">
          Edisco s'efforcera de satisfaire les demandes du client et d'exécuter
          les commandes de produits et services de ce dernier. Cependant, Edisco
          ne sera pas tenue d'accepter toutes les commandes et ne pourra voir sa
          responsabilité engagée à l'égard du client en cas de retard de
          livraison ou de non-disponibilité temporaire ou permanente de produits
          et services.
        </p>
        <h2 className="text-xl text-bgFooterLow font-semibold mt-8 ">
          Retours :
        </h2>
        <p className="mt-2">
          Nous nous engageons à vous fournir entière satisfaction pour
          l'ensemble de nos produits et services. Conformément à la législation
          régissant la vente par correspondance en vigueur pour la vente en
          ligne, vous avez la possibilité de nous retourner n'importe quel
          article dans les 7 jours suivant la date de livraison en étant
          intégralement remboursé du prix de l'article concerné (les frais de
          livraison ne sont pas remboursés). Toutefois les articles ne
          correspondant pas à votre attente doivent nous être retournés à l'état
          neuf dans leur emballage d'origine.
        </p>
        <h2 className="text-xl text-bgFooterLow font-semibold  mt-8">
          Cas de force majeure :
        </h2>
        <p className="mt-2">
          La responsabilité de Edisco ne pourra être engagée dans le cas de
          force majeure tels que grèves, incendies, catastrophes naturelles, ou
          autres, cette liste n'étant pas exhaustive.
        </p>
        <h2 className="text-xl text-bgFooterLow font-semibold mt-8  ">
          Paiement :
        </h2>
        <p className="mt-2">
          Le paiement des produits et services peut s'effectuer par carte
          bancaire à la commande en mode sécurisé, chèque bancaire à l'ordre de
          Edisco, sous réserve de toutes les vérifications nécessaires. Jusqu'à
          réception effective de l'intégralité des sommes qui lui sont dues dans
          leur intégralité, Edisco reste propriétaire des produits livrés et se
          réserve le droit de procéder à leur récupération par tous moyens mis à
          sa disposition en cas de défaillance du règlement définitif.
        </p>
        <h2 className="text-xl text-bgFooterLow font-semibold  mt-8">
          Garantie :
        </h2>
        <p className="mt-2">
          Les produits distribués par la librairie sont garantis par leurs
          fournisseurs ou éditeurs respectifs.
        </p>
        <h2 className="text-xl text-bgFooterLow font-semibold  mt-8">
          Informatique et liberte :
        </h2>
        <p className="mt-2">
          Le client dispose d'un droit d'accès, de modification et de
          suppression des données qui le concernent (art.34 de la loi
          "Informatique et Libertés"). Pour l'exercer, adressez-vous à Edisco,
          Domaine de Montplaisir, 2 rue Quarin, 81160 - Saint-Juéry France ou à
          info@edisco.com. Les seules informations recueillies sous forme de
          cookie concernent les références des produits et services commandés et
          un numéro d'identifiant généré de façon aléatoire.
        </p>
        <h2 className="text-xl text-bgFooterLow font-semibold  mt-8">
          Litiges :
        </h2>
        <p className="mt-2">
          En cas de litige, les parties font attribution de juridiction au
          Tribunal de Commerce 81000 - Albi France. Toutefois les articles ne
          correspondant pas à votre attente doivent nous être retournés à l'état
          neuf dans leur emballage d'origine.
        </p>
        <h2 className="text-xl text-bgFooterLow font-semibold mt-8 ">
          Cas de force majeure :
        </h2>
        <p className="mt-2">
          La responsabilité de Edisco ne pourra être engagée dans le cas de
          force majeure tels que grèves, incendies, catastrophes naturelles, ou
          autres, cette liste n'étant pas exhaustive
        </p>
      </div>
    </div>
  );
};

export default Droits;
