import React from "react";
import { Helmet } from "react-helmet-async";

const Politique = () => {
  return (
    <div>
      <Helmet>
        <title>Edisco-CHL | Politique de confidentialité </title>
        <meta
          name="description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta name="keywords" content="grossiste dentaire" />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Edisco-CHL | Vente de Produits Dentaires"
        />
        <meta
          name="twitter:description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          property="og:description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          property="og:title"
          content="Edisco-CHL | Vente de Produits Dentaires"
        />
        <link rel="canonical" href="/politique-de-confidentialite" />
      </Helmet>
      <div className="w-full items-center justify-between">
        <h1
          className="text-2xl mb-10 font-semibold text-bgFooter relative 
            before:absolute before:rounded-lg before:content before:w-20 before:h-1 
            before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-blue-200 to-bgFooter transition-all ease-in-out
            duration-100"
        >
          Politique de confidentialité d’Edisco
        </h1>
      </div>

      <div className="w-full text-base items-center text-bgFooter justify-between">
        <p className="mt-2">
          Les informations recueillies font l’objet d’un traitement informatique
          réalisé par l’entreprise comptoir du haut Languedoc (nom d’enseigne
          edisco – chl) et sont indispensables au traitement de la mission
          confiée.
          <br />
          <br />
          Avant de pouvoir enregistrer les données nécessaires, nous vous prions
          de lire ce qui suit.
        </p>

        <h2 className="text-xl  mt-8 text-bgFooterLow font-semibold  ">
          1. Responsable du traitement des données à caractère personnel
        </h2>
        <div className="mt-2">
          Le responsable du traitement des données à caractère personnel est :
          <ul className="mt-2 italic">
            <li className=" text-bgFooterLow font-semibold  ">
              BOUCHE Anne-Marie
            </li>
            <li>Présidente </li>
            <li>2 rue Quarin 81160 Saint-Juéry</li>
            <li>France</li>
          </ul>
          <p className="mt-2">
            Pour toute question relative à la protection des données à caractère
            personnel, veuillez-vous adresser :
          </p>
          <ul className="ml-6">
            <li>
              • par courrier postal à l’adresse ci-dessus : <br />
              <p className="ml-4 italic">
                {" "}
                2 rue Quarin 81160 Saint-Juéry <br />
                France
              </p>
            </li>
            <li>
              • par courrier électronique : <br />
              <a
                className="flex ml-4 underline italic"
                href="mailto:info@edisco-chl.com"
              >
                info@edisco-chl.com
              </a>
            </li>
          </ul>
        </div>
        <h2 className="text-xl mt-8 text-bgFooterLow font-semibold  ">
          2. Finalités du traitement des données à caractère personnel
        </h2>
        <div className="mt-2">
          La société comptoir du haut Languedoc (Nom d’enseigne Edisco-chl)
          traite les données à caractère personnel aux fins suivantes :
          <ul className="ml-6">
            <li>• Création de devis </li>
            <li>• Création de Facture</li>
            <li>• Envoi de colis</li>
            <li>• Appel d’information</li>
            <li>• Envoi de mail</li>
            <li>• Envoi d’offre</li>
            <li>• Analyse </li>
          </ul>
          <br />
          Les données collectées dans ce cadre ne sont utilisées dans aucun
          autre but.
          <br />
          Conformément à ce qui précède, et hormis s’il est nécessaire de
          communiquer des données à caractère personnel à des entreprises dont
          l’intervention en tant que tiers prestataires de services pour le
          compte et sous le contrôle du responsable est requise aux fins
          précitées, nous ne transmettrons pas les données à caractère
          personnel, ni ne les vendrons, les louerons ou les échangerons avec
          une quelconque organisation ou entité, à moins que vous n’en ayez été
          informé(e) au préalable et que vous ayez explicitement donné votre
          consentement ou à moins que la loi ne l’exige, par exemple dans le
          cadre d’une procédure judiciaire.
        </div>
        <h2 className="text-xl mt-8 text-bgFooterLow font-semibold  ">
          3. Quelles données à caractère personnel ?
        </h2>
        <p className="mt-2">
          Dans le cadre des finalités mentionnées au point 2, notre organisme
          est autorisé à traiter les données à caractère personnel suivantes :
          <ul className="ml-6">
            <li>• Nom, Prénom ou/et nom d’enseigne </li>
            <li>• Adresse postale</li>
            <li>• Adresse électronique </li>
            <li>• Numéro de téléphone</li>
            <li>• Quantité et produit commandé</li>
          </ul>
          <br />
          Notre organisme traite uniquement les données à caractère personnel
          que vous nous transmettez.
          <br />
          Les données ne sont traitées que si ce traitement est nécessaire aux
          fins mentionnées au point 3.
          <br />
          Les données à caractère personnel ne sont pas transmises à des pays
          tiers ni à des organisations internationales.
        </p>
        <h2 className="text-xl text-bgFooterLow font-semibold  mt-8">
          4. Destinataire des données
        </h2>
        <p className="mt-2">
          L’accès aux données personnelles sera strictement limité aux employés
          du responsable de traitement, habilités à les traiter en raison de
          leurs fonctions. Notre organisme fait appel à des tiers prestataires
          de services (sous-traitants) :
          <br />
          <br />
          Nous faisons appel à Fiducial informatique ou Orange pour la gestion
          des problèmes informatiques.
          <br />
          Nous faisons appel à une comptable et une expert-comptable du bureau
          Fiducial pour le traitement des bilans et des comptes de résultat.
          <br />
          Prestataire externe pour la gestion du site internet Anthony Rodrigo{" "}
          <br />
          <br />
          Notre organisme prend toutes les mesures nécessaires afin de garantir
          une bonne gestion de son système informatique.
        </p>
        <h2 className="text-xl text-bgFooterLow font-semibold mt-8 ">
          5. Mesures de sécurité
        </h2>
        <p className="mt-2">
          Afin, dans la mesure du possible, d’empêcher tout accès non autorisé
          aux données à caractère personnel collectées dans ce cadre, notre
          organisme a élaboré des procédures en matière de sécurité et
          d’organisation. Ces procédures concernent à la fois la collecte et la
          conservation de ces données. Ces procédures s’appliquent également à
          tous les sous-traitants auxquels notre organisme fait appel.
          <br />
          En tant que responsable de traitement, notre organisme doit s'assurer
          de sa conformité au RGPD et de celles de ses sous-traitants.
          <br />
          Afin d’établir la conformité des sous-traitants avec le RGPD, nous
          avons établi un Avenant « Accord relatif aux traitements des données »
          qui prend en compte le Règlement et son impact sur la relation
          contractuelle avec ces derniers.
          <br />
          Conformément au RGPD, tous les sous-traitants de données doivent
          notamment :<br />
          <ul className="ml-6 mt-2">
            <li>
              - Traiter les données exclusivement selon les instructions du
              responsable de traitement de données{" "}
            </li>
            <li>
              - Garantir la confidentialité des données à caractère personnel
            </li>
            <li>
              - Informer le responsable de traitement en cas de transfert de
              données en dehors de l'Union européenne ou de l'Espace Économique
              Européen{" "}
            </li>
            <li>
              - Respecter les obligations de mise en œuvre de mesures techniques
              et organisationnelles appropriées afin de protéger les données à
              caractère personnel contre la perte ou toute autre forme de
              traitement notamment en mettant à sa disposition toutes les
              informations nécessaires pour démontrer le respect des
              obligations.
            </li>
          </ul>
        </p>
        <h2 className="text-xl text-bgFooterLow font-semibold  mt-8">
          6. Durée de conservation
        </h2>
        <p className="mt-2">
          Vos données à caractère personnel sont conservées pendant : <br />
          Le temps de la relation commercial puis 5 ans après la relation
          commerciale. <br />
          une fois la durée expirée, les données à caractère personnel sont
          effacées.
          <br />
        </p>
        <h2 className="text-xl text-bgFooterLow font-semibold mt-8  ">
          7. Droits d’accès, rectification, droit à l’oubli, portabilité des
          données, opposition, non-profilage et notification de failles de
          sécurité
        </h2>
        <p className="mt-2">
          Vous avez le droit de consulter et de faire rectifier les données
          visées. Vous avez également le droit à l’oubli, à la portabilité des
          données et à l’opposition. Pour exercer vos droits relatifs à toutes
          les autres données à caractère personnel, vous pouvez prendre contact
          avec : <br />
          <br />
          <ul className="mt-2 italic">
            <li className=" text-bgFooterLow font-semibold  ">
              BOUCHE Anne-Marie
            </li>
            <li>Présidente </li>
            <li>marie@edisco-chl.com</li>
            <li>05 63 78 21 90</li>
            <li>06 73 53 78 38</li>
          </ul>
        </p>
        <h2 className="text-xl text-bgFooterLow font-semibold mt-8 ">
          8. Consentement
        </h2>
        <p className="mt-2">
          Le client déclare avoir pris connaissance de ce qui précède et
          autorise l’organisme à traiter ses données à caractère personnel.{" "}
        </p>
      </div>
    </div>
  );
};

export default Politique;
