import { storage } from "../../firebase/utils";
import React, { useState } from "react";
import { motion } from "framer-motion";

import { useDispatch } from "react-redux";
import { addProductStart } from "../../redux/Products/products.actions";

import { TbDental,TbBrandProducthunt } from "react-icons/tb";
import {
  MdCloudUpload,
  MdOutlineDescription,
  MdRoomPreferences,
  MdDelete,
  MdEuro,
} from "react-icons/md";
import { categories } from "../../data/data";
import Loader from "../Loader";
import { CKEditor } from "ckeditor4-react";

const CreateContainer = (props) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [reference, setReference] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [pro, setPro] = useState(false);
  const [category, setCategory] = useState(null);
  const [imageAsset, setImageAsset] = useState(null);
  const [fields, setFields] = useState(false);
  const [alertStatus, setAlertStatus] = useState("danger");
  const [msg, setMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const uploadImage = (e) => {
    setIsLoading(true);
    const imageFile = e.target.files[0];
    const timestamp = Date.now().toString();
    const uploadTask = storage
      .ref(`Images/${timestamp}-${imageFile.name}`)
      .put(imageFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        //const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error) => {
        console.log("Erreur : "+error);
        setFields(true);
        setMsg("Erreur lors du téléchargement de l'image : Essayez encore 🙇");
        setAlertStatus("danger");
        setTimeout(() => {
          setFields(false);
          setIsLoading(false);
        }, 4000);
      },
      () => {
        const chemin = timestamp + "-" + imageFile.name;
        storage
          .ref("Images")
          .child(chemin)
          .getDownloadURL()
          .then((downloadURL) => {
            setImageAsset(downloadURL);
            setIsLoading(false);
            setFields(true);
            setMsg("Téléchargement de l'image réussi 😊");
            setAlertStatus("success");
            setTimeout(() => {
              setFields(false);
            }, 4000);
          });
      }
    );
  };

  const deleteImage = () => {
    setIsLoading(true);
    const deleteRef = storage.refFromURL(imageAsset);
    deleteRef.delete().then(() => {
      setImageAsset(null);
      setIsLoading(false);
      setFields(true);
      setMsg("Suppression de l'image réussi 😊");
      setAlertStatus("success");
      setTimeout(() => {
        setFields(false);
      }, 4000);
    });
  };

  const saveDetails = () => {
    setIsLoading(true);

    try {
      if (
        !title ||
        !description ||
        !reference ||
        !imageAsset ||
        !price ||
        !category ||
        category === "other"
      ) {
        setFields(true);
        setMsg("Les champs obligatoires ne peuvent pas être vides");
        setAlertStatus("danger");
        setTimeout(() => {
          setFields(false);
          setIsLoading(false);
        }, 4000);
      } else {
        const qty = 1;
        const imageURL = imageAsset;
        const id = `${Date.now()}`;
        dispatch(
          addProductStart({
            id,
            title,
            description,
            imageURL,
            category,
            reference,
            qty,
            price,
            pro
          })
        );
        setIsLoading(false);
        setFields(true);
        setMsg("Données téléchargées avec succès 😊");
        setAlertStatus("success");
        setTimeout(() => {
          setFields(false);
        }, 4000);
        clearData();
      }
    } catch (error) {
      console.log(error);
      setFields(true);
      setMsg("Erreur lors du téléchargement : Essayez encore 🙇");
      setAlertStatus("danger");
      setTimeout(() => {
        setFields(false);
        setIsLoading(false);
      }, 4000);
    }
  };

  const clearData = () => {
    setTitle("");
    setDescription("");
    setImageAsset(null);
    setReference("");
    setPrice("");
    setPro(false);
    //setCategory("Sélectionne la catégorie");
  };

  return (
    <div className="w-full min-h-screen flex items-center justify-center">
      <div
        className="w-[90%] md:w-[75%] border border-gray-300 
        rounded-lg p-4 flex flex-col items-center justify-center gap-4"
      >
        {fields && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`w-full p-2 rounded-lg text-center text-lg font-semibold
                ${
                  alertStatus === "danger"
                    ? "bg-red-400 text-red-800"
                    : " bg-emerald-400 text-emerald-800"
                }`}
          >
            {msg}
          </motion.p>
        )}

        <div
          className="w-full py-2 border-b border-x-gray-300 
          flex items-center gap-2"
        >
          <TbDental className="text-xl text-gray-700" />
          <input
            type="text"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Titre"
            className="w-full h-full text-lg bg-transparent
              outline-none border-none placeholder:text-gray-400
              text-textColor"
          />
        </div>

        <div className="w-full flex flex-col md:flex-row items-center gap-3">
          <div className="w-full h-1200 py-2 border-b border-gray-300 flex items-center gap-2  white-space:normal">
            <MdOutlineDescription className="text-2xl text-gray-700" />
            <CKEditor
              onChange={(evt) => setDescription(evt.editor.getData()) }
            />
          </div>
        </div>

        <div className="w-full flex flex-row items-center cursor-pointer" >
                <TbBrandProducthunt className="text-2xl text-gray-700"/>
                <label htmlFor="bordered-checkbox" className=" ml-2 text-base font-semibold cursor-pointer  text-bgFooterLow">
                  Produit professionnel ?
                </label>
                <input
                  id="bordered-checkbox"
                  type="checkbox"
                  value=""
                  onClick={() => setPro(!pro)}
                  className="ml-2 w-5 h-5 cursor-pointer text-bgFooter bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600"
                />
        </div>

        <div className="w-full">
          <select
            onChange={(e) => setCategory(e.target.value)}
            className="outline-none w-full text-base border-b-2
            border-gray-200 p-2 rounded-md cursor-pointer"
          >
            <option value="other" className="bg-white">
              Sélectionne la catégorie
            </option>
            {categories &&
              categories.map((item) => (
                <option
                  key={item.id}
                  className="text-base border-0 
                    outline-none capitalize bg-white text-bgFooterLow"
                  value={item.urlParamName}
                >
                  {item.name}
                </option>
              ))}
          </select>
        </div>

        <div className="group flex justify-center items-center flex-col border-2 border-dotted border-gray-300 w-full h-225 md:h-420 cursor-pointer rounded-lg">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {!imageAsset ? (
                <>
                  <label className="w-full h-full flex flex-col items-center justify-center cursor-pointer">
                    <div className="w-full h-full flex flex-col items-center justify-center gap-2">
                      <MdCloudUpload className="text-gray-500 text-3xl hover:text-gray-700" />
                      <p className="text-gray-500 hover:text-gray-700">
                        Clique içi pour télécharger
                      </p>
                    </div>
                    <input
                      type="file"
                      name="uploadimage"
                      accept="image/*"
                      onChange={uploadImage}
                      className="w-0 h-0"
                    />
                  </label>
                </>
              ) : (
                <>
                  <div className="relative h-full">
                    <img
                      src={imageAsset}
                      alt="uploaded"
                      className="w-full h-full object-cover"
                    />
                    <button
                      type="button"
                      className="absolute bottom-3 
                    right-3 p-3 rounded-full bg-red-500 text-xl
                    cursor-pointer outline-none hover:shadow-md 
                    duration-500 transition-all ease-in-out"
                      onClick={deleteImage}
                    >
                      <MdDelete className="text-white" />
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <div className="w-full flex flex-col md:flex-row items-center gap-3">
          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
            <MdRoomPreferences className="text-2xl text-gray-700" />
            <input
              type="text"
              required
              value={reference}
              onChange={(e) => setReference(e.target.value)}
              placeholder="Référence"
              className="w-full h-full text-lg bg-transparent outline-none 
                border-none placeholder:text-gray-400 text-textColor"
            />
          </div>

          <div className="w-full py-2 border-b border-gray-300 flex items-center gap-2">
            <MdEuro className="text-2xl text-gray-700" />
            <input
              type="text"
              required
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Prix"
              className="w-full h-full text-lg bg-transparent outline-none 
                border-none placeholder:text-gray-400 text-textColor"
            />
          </div>
        </div>

        <div className="flex items-center w-full">
          <button
            type="button"
            className="ml-0 md:ml-auto w-full md:w-auto 
            border-none outline-none bg-emerald-500 px-12 py-2 rounded-lg 
            text-lg text-white font-semibold "
            onClick={saveDetails}
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateContainer;
