import I1 from "../img/1158-FEG-B.webp";
import I2 from "../img/29670-OptiBond-SoloPlus-kit-B.webp";
import I3 from "../img/Revolution_Formula2_Contents.webp";
import I4 from "../img/Fujyrock.webp";

export const heroData = [
  {
    id: 1,
    name: "Fraise FG 1558 X 100",
    descp: "Pour couper ou percer des couronnes et bridges métalliques",
    price: "209",
    imageSrc: I1,
    url: "/produit/1661260518446",
  },
  {
    id: 2,
    name: "OptiBond Solo + x 100",
    descp: "Adhésif monocomposant à mordançage total",
    price: "223",
    imageSrc: I2,
    url: "/produit/1666011819816",
  },
  {
    id: 3,
    name: "Revolution formula A2",
    descp: "Pour les chirurgiens",
    price: "72",
    imageSrc: I3,
    url: "/produit/1661260439108",
  },
  {
    id: 4,
    name: "Fuji Rock Golden Brown",
    descp: "Plâtre naturel thixotrope de classe IV (12 kg)",
    price: "95",
    imageSrc: I4,
    url: "/produit/1661260666847",
  },
];

export const categories = [
  {
    id: 0,
    name: "Toutes catégories",
    urlParamName: "",
  },
  {
    id: 1,
    name: "Adhésifs",
    urlParamName: "adhesifs",
  },
  {
    id: 2,
    name: "Aiguilles Dentaires",
    urlParamName: "aiguilles",
  },
  {
    id: 3,
    name: "Blanchiment",
    urlParamName: "blanchiment",
  },
  {
    id: 4,
    name: "Ciment",
    urlParamName: "ciment",
  },
  {
    id: 5,
    name: "Cire",
    urlParamName: "cire",
  },
  {
    id: 6,
    name: "Coins interdentaires",
    urlParamName: "coins-interdentaires",
  },
  {
    id: 7,
    name: "Composites",
    urlParamName: "composites",
  },
  {
    id: 8,
    name: "Couronnes",
    urlParamName: "couronnes",
  },
  {
    id: 9,
    name: "Digues ",
    urlParamName: "digues",
  },
  {
    id: 10,
    name: "Divers",
    urlParamName: "divers",
  },
  {
    id: 11,
    name: "Endodontie",
    urlParamName: "endodontie",
  },
  {
    id: 12,
    name: "Fond de cavité",
    urlParamName: "fond-de-cavite",
  },
  {
    id: 13,
    name: "Fraise",
    urlParamName: "fraise",
  },
  {
    id: 14,
    name: "Instrumentation",
    urlParamName: "instrumentation",
  },
  {
    id: 15,
    name: "Matériaux à empreinte",
    urlParamName: "materiaux-a-empreinte",
  },
  {
    id: 16,
    name: "Matrices",
    urlParamName: "matrices",
  },
  {
    id: 17,
    name: "Mordançage ",
    urlParamName: "mordancage",
  },
  {
    id: 18,
    name: "Orthodontie",
    urlParamName: "orthodontie",
  },
  {
    id: 19,
    name: "Polissage",
    urlParamName: "polissage",
  },
  {
    id: 20,
    name: "Papier à Articuler",
    urlParamName: "papier-à-articuler",
  },
  {
    id: 21,
    name: "Prophylaxie",
    urlParamName: "prophylaxie",
  },
  {
    id: 22,
    name: "Résine dentaire",
    urlParamName: "resine-dentaire",
  },
  {
    id: 23,
    name: "Stérilisation – Hygiène dentaire",
    urlParamName: "sterilisation–hygiene-dentaire",
  },
  {
    id: 24,
    name: "Tenons, Pivots dentaire",
    urlParamName: "tenons-pivots-dentaire",
  },
  {
    id: 25,
    name: "Usage Unique",
    urlParamName: "usage-unique",
  },
  {
    id: 26,
    name: "Laboratoire de prothèses",
    urlParamName: "laboratoire-de-protheses",
  },
];
