import React from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { Helmet } from "react-helmet-async";

const Livraison = () => {
  return (
    <div>
      <Helmet>
        <title>Edisco-CHL | Livraison</title>
        <meta
          name="description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta name="keywords" content="grossiste dentaire" />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Edisco-CHL | Vente de Produits Dentaires"
        />
        <meta
          name="twitter:description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          property="og:description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          property="og:title"
          content="Edisco-CHL | Vente de Produits Dentaires"
        />
        <link rel="canonical" href="/livraison" />
      </Helmet>
      <div className="w-full items-center justify-between">
        <h1
          className="text-2xl mb-10 font-semibold text-bgFooter relative 
            before:absolute before:rounded-lg before:content before:w-20 before:h-1 
            before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-blue-200 to-bgFooter transition-all ease-in-out
            duration-100"
        >
          Livraison
        </h1>
      </div>

      <div className="w-full text-base items-center text-bgFooter justify-between">
        <div className="text-xl text-bgFooterLow font-semibold  ">
          Envoie sous 24 heures en Colissimo suivi
        </div>
        <p className="mt-2">
          Nous traitons votre commande dès réception et l’envoyons sous 24 ou 48
          heures. Nous vous transmettons par la suite votre numéro de suivi pour
          voir étape par étape l’arrivée de votre colis. Vous avez un jour où
          vous préférez être livré ? Pas de soucis nous adaptons le jour de
          livraison Selon taille et dans la limite des stocks disponibles
        </p>
        <div className="text-xl mt-8 text-bgFooterLow font-semibold  ">
          Livraison offerte dès 150 € d'achat
        </div>
        <p className="mt-2">
          En-dessous de 150€ d'achat sur votre commande, la préparation et la
          livraison de votre commande vous seront facturées 10 € seulement.
        </p>
        <div className="text-xl mt-8 text-bgFooterLow font-semibold  ">
          Une préparation de haute vigilance
        </div>
        <p className="mt-2">
          Dès réception de votre commande, vos colis sont préparés avec soin par
          nos équipes avant de vous être envoyés. Les produits considérés
          fragiles sont protégés.
        </p>
        <div className="text-xl mt-8 text-bgFooterLow font-semibold  ">
          Un respect des normes européenne et de la qualité
        </div>
        <p className="mt-2">
          Tous les produits dentaires que nous vous proposons sont testés et
          approuvés par des praticiens expérimentés et répondent aux
          réglementations européennes très strictes de la Directive 93/42/CEE.
          Tous nos dispositifs médicaux portent le marquage CE vous garantissant
          une sécurité totale et une performance optimale certifiées.
        </p>
        <div className="text-xl mt-8 text-bgFooterLow font-semibold  ">
          Livraison en France métropolitaine uniquement
        </div>
        <p className="mt-2">
          Si cas contraire, contactez-nous pour un devis gratuit à l’adresse{" "}
          <span
            className="cursor-pointer"
            href="mailto: commande@edisco-chl.com"
          >
            {" "}
            commande@edisco-chl.com
          </span>{" "}
          ou au{" "}
          <span className="cursor-pointer" href="tel:+33563782190">
            +33 5 63 78 21 90 .
          </span>
        </p>
        <div className="text-xl mt-8 text-bgFooterLow font-semibold  ">
          Des questions ? Contactez-nous !
        </div>
        <p className="mt-2">
          Notre Service Client est ouvert du lundi au vendredi de 9h à 18h.
          N'hésitez pas à le contacter par :
        </p>
        <div className="mb-5 ml-8">
          <ul className="text-bgFooter text-base py-6">
            <li className="pb-4 items-center">
              <a className="flex" href="tel:+33563782190">
                <BsFillTelephoneFill className="mr-2 text-bgFooterLow text-2xl cursor-pointer" />{" "}
                +33 5 63 78 21 90{" "}
              </a>
            </li>
            <li className="pb-4 flex items-center">
              <a className="flex" href="tel:+33673537838">
                <BsFillTelephoneFill className="mr-2 text-2xl text-bgFooterLow cursor-pointer" />
                +33 6 73 53 78 38{" "}
              </a>
            </li>
            <li className="pb-4 flex items-center">
              <a className="flex" href="mailto:info@edisco-chl.com">
                <GrMail className="mr-3 text-2xl text-bgFooterLow cursor-pointer" />
                info@edisco-chl.com
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Livraison;
