import React from "react";

import HomeContainer from "../../components/HomeContainer";
import ProductsContainer from "../../components/ProductsContainer";
import { Helmet } from "react-helmet-async";

const Homepage = (props) => {
  return (
    <section className="items-center justify-center  md:gap-8 flex flex-col h-auto">
      <Helmet>
        <title>Edisco-CHL | Vente de Produits Dentaires</title>
        <meta
          name="description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          name="keywords"
          content=" Adhesifs, Aiguilles Dentaires,Blanchiment,Ciment,Cire,Coins interdentaires,Composites,Couronnes,Digues,Divers,Endodontie,Fond de cavité,Fraise,Instrumentation,Matériaux à empreinte,Matrices,Mordançage ,Orthodontie,Polissage,Papier à Articuler ,Prophylaxie,Résine dentaire, Stérilisation – Hygiène dentaire, Tenons, Pivots dentaire, Usage Unique, Laboratoire de prothèses"
        />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Edisco-CHL | Vente de Produits Dentaires"
        />
        <meta
          name="twitter:description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          property="og:description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          property="og:title"
          content="Edisco-CHL | Vente de Produits Dentaires"
        />
      </Helmet>
      <HomeContainer />
      <ProductsContainer />
    </section>
  );
};

export default Homepage;
