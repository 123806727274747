import React from "react";

import SearchProducts from "../../components/SearchProducts";
import { Helmet } from "react-helmet-async";

const ProduitsSearch = (props) => {
  return (
    <section>
      <Helmet>
        <title>Edisco-CHL | Nos produits</title>
        <meta
          name="description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          name="keywords"
          content=" Adhesifs, Aiguilles Dentaires,Blanchiment,Ciment,Cire,Coins interdentaires,Composites,Couronnes,Digues,Divers,Endodontie,Fond de cavité,Fraise,Instrumentation,Matériaux à empreinte,Matrices,Mordançage ,Orthodontie,Polissage,Papier à Articuler ,Prophylaxie,Résine dentaire, Stérilisation – Hygiène dentaire, Tenons, Pivots dentaire, Usage Unique, Laboratoire de prothèses"
        />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Edisco-CHL | Vente de Produits Dentaires"
        />
        <meta
          name="twitter:description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          property="og:description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          property="og:title"
          content="Edisco-CHL | Vente de Produits Dentaires"
        />
        <link rel="canonical" href="/search" />
      </Helmet>


      <div className="w-full flex flex-col items-center justify-center">
        <h1
          className="text-2xl font-semibold capitalize text-bgFooter relative 
            before:absolute before:rounded-lg before:content before:w-16 before:h-1 
            before:-bottom-2 before:left-0   before:bg-gradient-to-tr from-blue-200 to-bgFooter transition-all ease-in-out
            duration-100 mr-auto"
        >
          Nos produits
        </h1>

        <div className="w-full h-auto">
          <SearchProducts />
        </div>
      </div>



      
    </section>
  );
};

export default ProduitsSearch;
