import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signUpUserStart } from "../../redux/User/user.actions";
import {
  FaUser,
  FaMapMarkerAlt,
  FaMapMarker,
  FaPhoneAlt,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { BsPinMapFill } from "react-icons/bs";
import { RiLockPasswordFill } from "react-icons/ri";
import { BsBuilding } from "react-icons/bs";
import { SiOpenstreetmap } from "react-icons/si";
import { TiSortNumericallyOutline } from "react-icons/ti";
import Waiting from "../Waiting";
import Logo from "../../img/logo-removebg-preview.webp";
import { Link, useHistory } from "react-router-dom";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
  userErr: user.userErr,
});

const SignUp = (props) => {
  const { currentUser, userErr } = useSelector(mapState);
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [apeCode, setApeCode] = useState("");
  const [addressComplement, setAddressComplement] = useState("");
  const [errors, setErrors] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isPro, setIsPro] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickVisibilityPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (currentUser) {
      reset();
      history.push("/");
    }
  }, [currentUser, history]);

  useEffect(() => {
    if (Array.isArray(userErr) && userErr.length > 0) {
      setErrors(userErr);
    }
  }, [userErr]);

  const reset = () => {
    setDisplayName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setAddress("");
    setCity("");
    setCompany("");
    setCountry("");
    setApeCode("");
    setPhone("");
    setAddressComplement("");
    setErrors([]);
    setIsPro(false);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (password === confirmPassword) {
      setIsLoading(true);
    }
    dispatch(
      signUpUserStart({
        displayName,
        email,
        password,
        confirmPassword,
        address,
        city,
        country,
        phone,
        company,
        apeCode,
        isPro,
        addressComplement,
      })
    );
  };

  return (
    <div className="flex items-center justify-center">
      {isLoading && <Waiting />}
      <div className="w-full min-h-screen flex flex-col items-center justify-center pb-10">
        <Link to="/" className="flex items-center">
          <img
            src={Logo}
            alt="logo"
            className="w-60 min-h-[60px] min-w-[170px] object-cover"
          />
          <p className="text-bgFooter text-xl font-bold"></p>
        </Link>

        <div
          className="mt-8 w-[90%] md:w-[40%] border border-gray-300 
        rounded-lg p-4 flex flex-col items-center justify-center gap-4"
        >
          <div className="mt-1 text-xl font-semibold text-bgFooterLow items-center justify-center ">
            Inscription
          </div>

          <form onSubmit={handleFormSubmit}>
          {errors.length > 0 && (
              <ul className="text-base items-center justify-center mt-6 text-red-500">
                {errors.map((e, index) => {
                  return <li key={index}>{e}</li>;
                })}
              </ul>
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              <div
                className="w-full py-2 border-b border-x-gray-300 
                    flex items-center gap-2"
              >
                <FaUser className="text-xl text-gray-700" />
                <input
                  type="text"
                  required
                  name="displayName"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                  placeholder="Nom Prénom"
                  className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
                />
              </div>
              <div
                className="w-full py-2 border-b border-x-gray-300 
                    flex items-center gap-2"
              >
                <FaPhoneAlt className="text-xl text-gray-700" />
                <input
                  type="tel"
                  id="phone"
                  required
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="+33xxxxxxxxx"
                  className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
                />
              </div>
            </div>

            <div className="mt-2 w-full py-2 border-b-2 border-t-2 border-x-gray-300 flex flex-col  gap-2">
              <div className="w-full flex flex-row items-center cursor-pointer">
                <label
                  htmlFor="bordered-checkbox"
                  className="cursor-pointer text-base text-bgFooterLow"
                >
                  J'affirme être un professionnel
                </label>
                <input
                  id="bordered-checkbox"
                  type="checkbox"
                  value=""
                  onClick={() => setIsPro(!isPro)}
                  className="ml-2  w-5 h-5 cursor-pointer text-bgFooter bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600"
                />
              </div>

              {isPro && (
                <div>
                  <div
                    className="w-full py-2 border-b border-x-gray-300 
                              flex items-center gap-2"
                  >
                    <BsBuilding className="text-xl text-gray-700" />
                    <input
                      type="text"
                      name="company"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      placeholder="Entreprise"
                      className="w-full h-full text-lg bg-transparent
                                  outline-none border-none placeholder:text-gray-400
                                  text-textColor"
                    />
                  </div>
                  <div
                    className="w-full py-2 border-b border-x-gray-300 
                                          flex items-center gap-2"
                  >
                    <TiSortNumericallyOutline className="text-xl text-gray-700" />
                    <input
                      type="text"
                      name="apeCode"
                      value={apeCode}
                      onChange={(e) => setApeCode(e.target.value)}
                      placeholder="Code APE"
                      className="w-full h-full text-lg bg-transparent
                                              outline-none border-none placeholder:text-gray-400
                                              text-textColor"
                    />
                  </div>
                </div>
              )}
            </div>

            <div
              className="w-full mt-4 py-2 border-b border-x-gray-300 
                flex items-center gap-2"
            >
              <BsPinMapFill className="text-xl text-gray-700" />
              <input
                type="text"
                required
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Adresse"
                className="w-full h-full text-lg bg-transparent
                    outline-none border-none placeholder:text-gray-400
                    text-textColor"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              <div
                className="w-full py-2 border-b border-x-gray-300 
                    flex items-center gap-2"
              >
                <FaMapMarkerAlt className="text-xl text-gray-700" />
                <input
                  type="text"
                  required
                  name="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="Ville"
                  className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
                />
              </div>
              <div
                className="w-full py-2 border-b border-x-gray-300 
                    flex items-center gap-2"
              >
                <FaMapMarker className="text-xl text-gray-700" />
                <input
                  type="text"
                  required
                  name="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  placeholder="Pays"
                  className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
                />
              </div>
            </div>
            <div
              className="w-full py-2 border-b border-x-gray-300 
                    flex items-center gap-2"
            >
              <SiOpenstreetmap className="text-xl text-gray-700" />
              <input
                type="text"
                name="addressComplement"
                value={addressComplement}
                onChange={(e) => setAddressComplement(e.target.value)}
                placeholder="Complément adresse (facultatif)"
                className="w-full h-full text-lg bg-transparent
                        outline-none border-none placeholder:text-gray-400
                        text-textColor"
              />
            </div>
            <div
              className="w-full mt-4  py-2 border-b border-x-gray-300 
                flex items-center gap-2"
            >
              <MdEmail className="text-xl text-gray-700" />
              <input
                type="email"
                required
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="w-full h-full text-lg bg-transparent
                    outline-none border-none placeholder:text-gray-400
                    text-textColor"
              />
            </div>

            <div
              className="w-full py-2 border-b border-x-gray-300 
                flex items-center gap-2"
            >
              <RiLockPasswordFill className="text-xl text-gray-700" />
              <input
                type={showPassword ? "text" : "password"}
                required
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Mot de passe"
                className="w-full h-full text-lg bg-transparent
                    outline-none border-none placeholder:text-gray-400
                    text-textColor"
              />
              {showPassword ? (
                <AiOutlineEye
                  onClick={handleClickVisibilityPassword}
                  className="cursor-pointer text-xl text-gray-700"
                />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={handleClickVisibilityPassword}
                  className="cursor-pointer text-xl text-gray-700"
                />
              )}
            </div>

            <div
              className="w-full py-2 border-b border-x-gray-300 
                flex items-center gap-2"
            >
              <RiLockPasswordFill className="text-xl text-gray-700" />
              <input
                type={showPassword ? "text" : "password"}
                required
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirmation Mot de passe"
                className="w-full h-full text-lg bg-transparent
                    outline-none border-none placeholder:text-gray-400
                    text-textColor"
              />
              {showPassword ? (
                <AiOutlineEye
                  onClick={handleClickVisibilityPassword}
                  className="cursor-pointer text-xl text-gray-700"
                />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={handleClickVisibilityPassword}
                  className="cursor-pointer text-xl text-gray-700"
                />
              )}
            </div>

            <div className="flex mt-2 items-center justify-center w-full">
              <input
                type="submit"
                className="w-auto 
                    border-none outline-none bg-emerald-500 px-12 py-2 rounded-lg 
                    text-white font-semibold cursor-pointer "
                value="S'inscrire"
              />
            </div>
          </form>

          <div className="text-sm items-center justify-center">
            En vous inscrivant, vous acceptez nos{" "}
            <a
              className="cursor-pointer font-semibold decoration-solid "
              target="_blank"
              href="/droits"
            >
              Conditions générales de vente{" "}
            </a>{" "}
            et notre{" "}
            <a
              className="cursor-pointer font-semibold decoration-solid "
              target="_blank"
              href="/politique-de-confidentialite"
            >
              Politique de confidentialité
            </a>
            .
          </div>
        </div>

        <p className="text-base cursor-pointer"></p>
        <Link to="/login">
          <p className="text-base cursor-pointer"></p>
        </Link>

        <div className="mt-6 text-base font-bold cursor-pointer">
          Déjà inscrit sur Edisco ?
        </div>
        <Link to="/login">
          <button
            type="button"
            className=" mt-2 bg-gradient-to-br   from-blue-200 to-bgFooter w-full 
                md:w-auto px-4 py-2 cursor-pointer rounded-lg hover:shadow-lg transition-all ease-in-out 
                duration-100 text-base text-white"
          >
            Connectez-vous
          </button>
        </Link>
      </div>
    </div>
  );
};

export default SignUp;
