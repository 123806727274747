import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserOrderHistory } from "./../../redux/Orders/orders.actions";
import OrderHistory from "./../../components/OrderHistory";
import Profile from "./../../components/Profile";
import Credentials from "../../components/Credentials";

const mapState = ({ user, ordersData }) => ({
  currentUser: user.currentUser,
  orderHistory: ordersData.orderHistory.data,
});

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const { currentUser, orderHistory } = useSelector(mapState);


  useEffect(() => {
    dispatch(getUserOrderHistory(currentUser));
  }, [currentUser, dispatch]);

  return (
    <div className="w-full h-auto flex flex-col items-center justify-center">
      <div className="w-full items-center justify-between">
        <h1
          className="text-2xl mb-10 font-semibold text-bgFooter relative 
            before:absolute before:rounded-lg before:content before:w-20 before:h-1 
            before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-blue-200 to-bgFooter transition-all ease-in-out
            duration-100"
        >
          Historique de commande
        </h1>
      </div>
      <div className="w-full h-auto flex flex-col items-center justify-center">
        <OrderHistory orders={orderHistory} />
      </div>
      <div className="mt-8 w-full items-center justify-between">
        <h1
          className="text-2xl mb-10 font-semibold text-bgFooter relative 
            before:absolute before:rounded-lg before:content before:w-20 before:h-1 
            before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-blue-200 to-bgFooter transition-all ease-in-out
            duration-100"
        >
          Informations personnelles
        </h1>
      </div>
      <div className="w-full h-auto flex flex-col items-center justify-center">
        <Profile />
      </div>
      <div className=" mt-8 w-full items-center justify-between">
        <h1
          className="text-2xl mb-10 font-semibold text-bgFooter relative 
            before:absolute before:rounded-lg before:content before:w-20 before:h-1 
            before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-blue-200 to-bgFooter transition-all ease-in-out
            duration-100"
        >
          Mes identifiants
        </h1>
      </div>
      <div className="w-full h-auto flex flex-col items-center justify-center">
        <Credentials />
      </div>
    </div>
  );
};

export default Dashboard;
