import React from "react";
import timeline from "../../img/timeline.webp";
import promesse from "../../img/Promesse.webp";
import { Helmet } from "react-helmet-async";

const APropos = () => {
  return (
    <div>
      <Helmet>
        <title>Edisco-CHL | A propos</title>
        <meta
          name="description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta name="keywords" content="grossiste dentaire" />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Edisco-CHL | Vente de Produits Dentaires"
        />
        <meta
          name="twitter:description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          property="og:description"
          content="Edisco propose la vente de fourniture pour le dentiste. La qualité, le service, des prix bas, un paiement sécurisé et livraison rapide sont les points forts d'Edisco."
        />
        <meta
          property="og:title"
          content="Edisco-CHL | Vente de Produits Dentaires"
        />
        <link rel="canonical" href="/apropos" />
      </Helmet>
      <div className="w-full items-center justify-between">
        <h1
          className="text-2xl mb-10 font-semibold text-bgFooter relative 
            before:absolute before:rounded-lg before:content before:w-20 before:h-1 
            before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-blue-200 to-bgFooter transition-all ease-in-out
            duration-100"
        >
          A propos
        </h1>
      </div>
      <img
        src={promesse}
        className="w-full lg:w-9/12 items-center mt-10  "
        alt="promesse"
      />
      <div className="mt-10 w-full text-base items-center text-bgFooter justify-between">
        <div className=" text-bgFooterLow font-semibold  ">
          Cher Client, <br />
          <br />
        </div>
        <h2 className="mt-1">
          Nous sommes une société de grossiste dentaire fondé en 1963 à Alban
          dans le Tarn.
          <br />
          <br />
          Nous mettons à votre disposition nos 59 ans d’experience et celle de
          nos fournisseurs pour être toujours auprès de vous.
          <br /> <br />
          Nous avons une stratégie de pénétration pour essayer de vous garantir
          les prix les plus bas et les plus intéressants toutes l’année.
        </h2>
      </div>
      <img
        src={timeline}
        className="w-full items-center mt-10  lg:w-9/12 "
        alt="timeline"
      />
    </div>
  );
};

export default APropos;
